import React, { useCallback, useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { Button, Form, Spinner, TabPane } from "reactstrap";

import {
  Html5Qrcode,
  Html5QrcodeScanner,
  Html5QrcodeScanType,
  Html5QrcodeSupportedFormats,
} from "html5-qrcode";

import { BarcodeScanner } from "react-barcode-scanner";
import "react-barcode-scanner/polyfill";

import {
  HtmlQrcodeAdvancedPlugin,
  IHtmlQrcodePluginForwardedRef,
} from "./PluginQRCode";

import { useFetchCameras } from "./useFetchCameras";

import IconScan from "./icon_scan.svg";
import CameraIcon from "./camera_change_icon.svg";
import BarCode from "./barcode_image.svg";

import "./firstTab.styles.scss";

const CONFIG = {
  fps: 4,
  formatsToSupport: [
    Html5QrcodeSupportedFormats.CODE_128,
    Html5QrcodeSupportedFormats.QR_CODE,
    Html5QrcodeSupportedFormats.AZTEC,
    Html5QrcodeSupportedFormats.CODABAR,
    Html5QrcodeSupportedFormats.CODE_39,
    Html5QrcodeSupportedFormats.CODE_93,
    Html5QrcodeSupportedFormats.DATA_MATRIX,
    Html5QrcodeSupportedFormats.ITF,
    Html5QrcodeSupportedFormats.EAN_13,
    Html5QrcodeSupportedFormats.EAN_8,
    Html5QrcodeSupportedFormats.PDF_417,
    Html5QrcodeSupportedFormats.UPC_A,
    Html5QrcodeSupportedFormats.UPC_E,
    Html5QrcodeSupportedFormats.RSS_14,
    Html5QrcodeSupportedFormats.RSS_EXPANDED,
  ],
  rememberLastUsedCamera: true,
  supportedScanTypes: [
    Html5QrcodeScanType.SCAN_TYPE_CAMERA,
    Html5QrcodeScanType.SCAN_TYPE_FILE,
  ],
};
const QRCODE_REGION = "SCANNER_EXAMPLE_QRCODE_REGION";

interface IInfoProps {
  title: string;
}

export const Info: React.FC<IInfoProps> = ({ title }: IInfoProps) => (
  <div>
    <h6>{title}</h6>
  </div>
);

const FirstTab = ({ wizardformik, toggleTab, activeTab, loading }) => {
  const {
    fetchCameras,
    state: { loadingScanner, error, cameraDevices },
  } = useFetchCameras();

  const ref = useRef<IHtmlQrcodePluginForwardedRef>(null);
  const [selectedCameraId, setSelectedCameraId] = useState<string | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [scanOpen, setScanOpen] = useState(false);

  // useEffect(() => {
  //   fetchCameras();
  // }, []);

  useEffect(() => {
    if (error) {
      setScanOpen(false);

      setErrorMessage(error.message);

      if (cameraDevices.length === 0 && loadingScanner === false) {
        setErrorMessage("Nenhuma câmera encontrada.");
      }
    }
  }, [fetchCameras]);

  const handleScanOpen = async () => {
    await fetchCameras();

    setScanOpen(true);
  };

  const [code, setCode] = useState("");

  const onCodeScanned = useCallback((code: any) => {
    wizardformik.setFieldValue("barcode", code.rawValue);

    console.log(code);

    setCode(code.rawValue);

    setScanOpen(false);
  }, []);

  const handleChangeCamera = () => {
    setScanOpen(false);

    setTimeout(() => {
      if (selectedCameraId === undefined) {
        setSelectedCameraId(cameraDevices[0].id);
      } else {
        setSelectedCameraId(cameraDevices[1].id);
      }

      setScanOpen(true);
    }, 500);
  };

  return (
    <TabPane tabId={1}>
      {scanOpen && cameraDevices.length > 1 && (
        <>
          <div className="modal-scanner">
            <div className="scanner">
              {/* {cameraDevices.length > 1 && (
                // <select
                //   defaultValue={selectedCameraId || cameraDevices[1].id}
                //   onChange={(event) => {
                //     handleChangeCamera(event.target.value);
                //   }}
                //   className="btn-change-camera"
                // >
                //   {cameraDevices.map((device) => (
                //     <option
                //       key={device.id}
                //       value={device.id}
                //       label={device.label}
                //     />
                //   ))}
                // </select>
                <button
                  type="button"
                  className="btn-change-camera"
                  onClick={handleChangeCamera}
                >
                  <img
                    src={CameraIcon}
                    alt="Imagem representando o icone da Camera"
                  />
                </button>
              )} */}
              <button
                type="button"
                className="btn-close-scanner"
                onClick={() => setScanOpen(false)}
              >
                [x] Fechar
              </button>
              <BarcodeScanner
                options={{
                  delay: 500,
                  formats: [
                    "qr_code",
                    "itf",
                    "code_128",
                    "code_39",
                    "code_93",
                    "codabar",
                    "ean_13",
                    "ean_8",
                    "upc_a",
                    "upc_e",
                  ],
                }}
                onCapture={onCodeScanned}
              />
            </div>
          </div>
        </>
      )}

      {/* <p>{code}</p> */}

      <Form
        onSubmit={wizardformik.handleSubmit}
        className="first-tab final-client"
      >
        <div>
          <h2>
            Digite ou cole o <span>número</span> do código de barras da sua
            conta de energia:
          </h2>

          <p>
            <i
              className="mdi mdi-information-outline mr-3"
              style={{ color: "#87B30A", fontSize: "20px" }}
            ></i>{" "}
            Você encontra os números próximos ao código de barras do boleto.
          </p>

          <div className="container-barcode-input">
            <InputMask
              mask={"99999.99999 99999.999999 99999.999999 9 999999999999999"}
              alwaysShowMask={false}
              maskChar={null}
              placeholder="Digite aqui o Número do Código de Barras"
              type="text"
              name="barcode"
              value={wizardformik.values.barcode}
              onChange={wizardformik.handleChange}
              onBlur={wizardformik.handleBlur}
              className={`barcode-input ${
                wizardformik.errors.barcode && "invalid"
              }`}
            />

            {wizardformik.errors.barcode && (
              <i className="mdi mdi-information-outline icon-danger"></i>
            )}
          </div>

          {wizardformik.errors.barcode && (
            <span className="text-danger error-barcode mt-1">
              {wizardformik.errors.barcode ||
                "Código de barras inválido. Verifique e tente novamente."}
            </span>
          )}

          <Button
            type="button"
            className="button-scan"
            onClick={handleScanOpen}
          >
            <img src={IconScan} />
            Escanear código
          </Button>

          {errorMessage !== null && (
            <div className="text-error-scanner">{errorMessage}</div>
          )}
        </div>

        <div>
          <img src={BarCode} alt="Imagem representando o Código de Barras" />
        </div>

        <div className="actions clearfix">
          <Button
            type="submit"
            disabled={loading}
            className={
              wizardformik.errors.barcode
                ? "button-continue disabled"
                : "button-continue"
            }
            // onClick={() => {
            //   toggleTab(activeTab + 1);
            // }}
          >
            {loading ? <Spinner /> : "Continuar"}
          </Button>
        </div>
      </Form>
    </TabPane>
  );
};

export default FirstTab;
