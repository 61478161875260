import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Container, Row, TabPane } from "reactstrap";

import Success from "./sucess_pix.svg";
import { useDispatch } from "react-redux";

import "./thirdTab.styles.scss";
import withRouter from "Components/Common/withRouter";

const ThirdTab = ({ toggleTab, activeTab, billToPay, resetState, payed, changePayedStatus, router }) => {
  const dispatch: any = useDispatch();
  const textRef = useRef<HTMLTextAreaElement>(null);

  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);

  const [activeCounter, setActiveCounter] = useState(false);
  const [pago, setPago] = useState(false);

  const startAccountant = () => {
    setActiveCounter(true);
  };

  useEffect(() => {
    if (activeTab === 3) {
      startAccountant();
    }
  }, [activeTab]);

  useEffect(() => {
    let contadorInterval;

    if (activeCounter) {
      if (minutes === 0 && seconds === 0) {
        console.log(minutes, seconds, "aqui dentro");

        clearInterval(contadorInterval);

        setMinutes(0);
        setSeconds(0);

        return;
      }

      contadorInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            setMinutes((prevMinutes) => prevMinutes - 1);
            return 59;
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);
    }

    // Cleanup do intervalo ao desmontar o componente
    return () => clearInterval(contadorInterval);
  }, [activeCounter, minutes, seconds]);

  const handleBack = () => {
    resetState();
    toggleTab(1);
  };
  
  const handleCredits = () => {
    // resetState();
    // toggleTab(1);
    dispatch(changePayedStatus(true));
  };

  const copyText = () => {
    if (textRef.current) {
      // Seleciona o texto dentro do elemento com useRef
      textRef.current.select();

      try {
        // Tenta copiar o texto para a área de transferência usando a API Clipboard
        document.execCommand("copy");
        // alert("Texto copiado para a área de transferência!");
      } catch (err) {
        console.error("Erro ao copiar o texto:", err);
        // alert("Falha ao copiar o texto. Por favor, copie manualmente.");
      }

      // Deseleciona o texto
      window.getSelection()?.removeAllRanges();
    }
  };

  return (
    <TabPane tabId={3}>
      <div className="third-tab d-flex flex-column align-items-center justify-content-center">
        {!payed && <>
          <Row className="text-center">
          <h3 className="title-tab">Pagamento via PIX</h3>
          <span className="describe">Escaneie o código abaixo:</span>
        </Row>

        <img src={billToPay?.qrCode?.base64} alt="PIX" className="pix-image mt-3" />

        <Row className="align-items-center justify-content-center text-center">
          <h5 className="pix-copy-paste">
            PIX Copia e Cola:
            <button onClick={copyText} style={{ background: 'none', border: 'none' }}>
              <i className="mdi mdi-content-copy"></i>
            </button>
          </h5>
          <textarea
            value={billToPay?.qrCode?.payload}
            readOnly
            ref={textRef}
            style={{ position: "absolute", left: "-9999px" }}
          />

          <p className="pix-countdown mb-4">
            Expira em:{" "}
            <span>
              {`${minutes.toString().padStart(2, "0")}min ${seconds
                .toString()
                .padStart(2, "0")}`}
              s
            </span>
          </p>
        </Row>
        <div className="actions clearfix second-tab-payment_actions flex-wrap">
          <button className="previous m-1" onClick={handleBack} style={{ padding: '10px 30px', border: 'none', background: '#dee1f3', color: '#495057' }}>
            Cancelar Pagamento
          </button>
          <button className="button-continue m-1" onClick={handleCredits} style={{ padding: '10px 30px', border: 'none' }}>
            Pagamento Efetuado
          </button>
        </div>
        </>}
        

        {/* <div className="error-pix">
          <img src={Error} alt="Error PIX" />

          <h3 className="title-tab text-center mt-5">O pagamento falhou</h3>

          <span className="describe mt-3 w-75 text-center">
            O tempo limite expirou. Tente novamente.
          </span>
        </div> */}

{payed && <>

        <div className="sucess-pix" style={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <img src={Success} alt="Success PIX" width="150px"/>

          <h3 className="title-tab text-center mt-5">
            Pagamento efetuado com sucesso!
          </h3>

          <span className="describe mt-3 w-75 text-center">
            O prazo médio para compensação do pagamento é de aproximadamente 5
            dias úteis.
          </span>

          <Link className="btn see-receipt" to="/client/historic">
            <i className="mdi mdi-receipt"></i>
            Ver Recibo
          </Link>
        </div>
</>}

        {/* <Container className="d-flex flex-column align-items-center justify-content-center">
          <div className="receipt">
            <h3 className="text-center">
              RECIBO <span>#0000324</span>
            </h3>

            <div className="mt-5">
              <p className="receipt-company">Company name</p>
              <p className="receipt-description">Invoice #6521</p>
              <p className="receipt-description">August 01 2017</p>

              <div className="d-flex justify-content-between">
                <p className="receipt-description">BS-200 (1 Pc)</p>
                <p className="receipt-description">$10.99</p>
              </div>

              <div className="receipt-total">
                <p>Total</p>
                <p>$670.99</p>
              </div>
            </div>
          </div>

          <div className="d-md-flex gap-4">
            <button className="btn btn-receipt">
              <i className="mdi mdi-file-download-outline"></i>
              Baixar PDF
            </button>
            <button className="btn btn-receipt">
              <i className="mdi mdi-share-variant-outline"></i>
              Compartilhar
            </button>
          </div>
        </Container> */}
      </div>
    </TabPane>
  );
};

export default withRouter(ThirdTab);
