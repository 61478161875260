import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
    credits: [
      {
        id: null,
        amount: null,
        createdAt: "",
        updatedAt: "",
        deletedAt: null,
        partner: {
          id: null,
          socialName: "",
          fantasyName: "",
          addressStreet: "",
          addressStreetNumer: "",
          addressZipCode: "",
          addressNeighborhood: "",
          addressCity: "",
          addressState: "",
          addressComplement: "",
          createdAt: "",
          updatedAt: "",
          deletedAt: null,
        },
      },
    ],
    bills: [
      {
        id: 0,
        status: "",
        code: "",
        line: "",
        type: "",
        company: "",
        amount: 0,
        totalToPay: 0,
        discount: 0,
        createdAt: "",
        updatedAt: "",
        deletedAt: null,
      }
    ],

  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const adminBackofficeSlice = createSlice({
  name: "adminBackoffice",
  initialState,
  reducers: {
    fetchPaymentsSuccess(state, action) {
      // User data
      state.credits = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    
    fetchBillsSuccess(state, action) {
      state.bills = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },   
    
    updatePaymentStatusSuccess(state, action) {
      // User data
      // state.bills = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },

    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    
    resetApiError(state, action) {
      state.error = '';
      state.loading = false;
      state.errorMsg = false;
    },

    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  fetchPaymentsSuccess,
  updatePaymentStatusSuccess,
  fetchBillsSuccess,
  isFetching,
  apiError,
  resetApiError,
} = adminBackofficeSlice.actions;

export const adminBackofficeSelector = (state: RootState) => state.AdminBackoffice;

export default adminBackofficeSlice.reducer;