import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import FreeBlockedBalanceChart from "Components/partnerCompany/reports/FreeBlockedBalanceChart";
import DistributionOfVouchers from "Components/partnerCompany/reports/DistributionOfVouchers";
import StackedColumnChart from "Components/finalClient/historic/graphic";
import ProjectionOfFutureVouchers from "Components/partnerCompany/reports/ProjectionOfFutureVouchers";
import FreeBlockedBalancePieChart from "Components/partnerCompany/reports/FreeBlockedBalancePieChart";

import classNames from "classnames";

import "./reports.styles.scss";
import { partnerDashboardSelector } from "slices/partner/dashboard/reducer";
import { useAppSelector } from "slices";

const monthData = [
  {
    name: "Vales Emitidos",
    data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
  },
];

const weekData = [
  {
    name: "Series A",
    data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
  },
];

const Reports = () => {
  document.title = "Relatórios | S Company";
  const { balance, myVouchers, loading, totalCounters } = useAppSelector(partnerDashboardSelector)
  const [chartVoucher, setChatVoucher] = useState([
    {
      name: "Vales Emitidos",
      data: myVouchers.reduce((acc: any, curr: any) => {
        const month = new Date(curr.createdAt).getMonth(); // Extrai o mês da data (0 = Janeiro, 1 = Fevereiro, etc.)
        acc[month] += curr.value; // Soma o valor ao acumulador no índice do mês
        return acc;
      }, Array(12).fill(0)),
    },
  ])

  const reports = [
    {
      title: "Total de Contas Pagas",
      iconClass: "bx-copy-alt",
      description: myVouchers.length,
      isMoney: false,
    },
    {
      title: "Saldo Livre",
      iconClass: "bx-archive-in",
      description: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(balance?.value) || 0),
    },
    {
      title: "Saldo Bloqueado",
      iconClass: "bx-purchase-tag-alt",
      description: (myVouchers[0] && Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(myVouchers.reduce((acc, voucher: any) => { return acc + Number(voucher?.value || 0)}, 0))) || 0,
    },
  ];

  const [periodData, setPeriodData] = useState<any>([]);
  const [periodType, setPeriodType] = useState<string>("yearly");

  useEffect(() => {
    if (periodType === "weekly") {
      setPeriodData(weekData);
    }
    if (periodType === "monthly") {
      setPeriodData(monthData);
    }
    if (periodType === "yearly") {
      setPeriodData(chartVoucher);
    }
  }, [periodType]);

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumb title="Início" breadcrumbItem="Relatórios" /> */}

          <Row>
            <Col>
              <Row>
                {(reports || []).map((report: any, key: number) => (
                  <Col key={"_col_" + key}>
                    <Card className="mini-stats-wid card-reports">
                      <CardBody>
                        <div className="flex-grow-1">
                          <p className="title-card">{report.title}</p>
                          <h4 className="card-description">
                            {report.isMoney && <span>R$</span>}
                            {report.description}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i> 
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                <Col xl={4}>
                  <DistributionOfVouchers myVouchers={myVouchers} />
                </Col>

                <Col xl={8}>
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        {/* <div className="ms-auto">
                          <Nav pills>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classNames(
                                  { active: periodType === "weekly" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("weekly");
                                }}
                                id="one_month"
                              >
                                Week
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classNames(
                                  { active: periodType === "monthly" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("monthly");
                                }}
                                id="one_month"
                              >
                                {" "}
                                Month{" "}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classNames(
                                  { active: periodType === "yearly" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("yearly");
                                }}
                                id="one_month"
                              >
                                {" "}
                                Year{" "}
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div> */}
                      </div>
                      <div className="clearfix"></div>
                      <StackedColumnChart
                        periodData={chartVoucher}
                        dataColors='["#3F9000", "#34C38F", "#aebfa1"]'
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            {/* <Col lg={5}>
              <Card>
                <CardBody>
                  <CardTitle tag="h4" className="mb-4">
                    Line with Data Labels
                  </CardTitle>
                  <FreeBlockedBalanceChart dataColors='["--bs-primary", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <ProjectionOfFutureVouchers dataColors='["#556EE6", "#4CF1DD"]' />
            </Col>

            <Col lg={5}>
              <CardTitle tag="h4" className="mb-4">
                Saldo Livre/Bloqueado
              </CardTitle>

              <div className="d-flex align-items-center justify-content-center">
                <FreeBlockedBalancePieChart dataColors='["#412FAE","#5598E6", "#6ADBF4","#34C38F", "#8258DA"]' />
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reports;
