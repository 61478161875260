import * as Yup from "yup";
import { useFormik } from "formik";

import EnergyVoucher from "Components/finalClient/home/energyVoucher";

import {
  Row,
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Button,
  Spinner,
  Modal,
} from "reactstrap";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import withRouter from "Components/Common/withRouter";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { partnerVoucherSelector, resetApiError } from "slices/partner/voucher/reducer";
import { authSelector } from "slices/auth/reducer";

import { postVoucher } from "slices/partner/voucher/thunk";
import { NumericFormat } from "react-number-format";

import "./IndividualIssuance.styles.scss";
import { useEffect, useState } from "react";

const IndividualIssuance = (props: any) => {
  const dispatch: any = useDispatch();

  const { vouchers, error, errorMsg, loading } = useAppSelector(partnerVoucherSelector);
  const { partner } = useAppSelector(authSelector);

  const [modalError, setModalError] = useState(false);
  const [voucherAmount, setVoucherAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validation: any = useFormik({
    initialValues: {
      clientName: "",
      clientEmail: "",
      clientDocument: "",
      isMonthRecurring: false,
      monthsToRecurring: 0,
      amount: ""
    },
    validationSchema: Yup.object({
      clientName: Yup.string().required("Por favor, digite o nome."),
      clientEmail: Yup.string().email().required("Por favor, digite o email."),
      clientDocument: Yup.string().required("Por favor, digite o CPF/CNPJ."),
      isMonthRecurring: Yup.bool(),
      monthsToRecurring: Yup.number(),
      amount: Yup.string().required("Por favor, digite o valor do Vale."),
    }),
    onSubmit: (values: any) => {
      values.clientDocument = values.clientDocument.replace(/\D/g, '');
      console.log('on submit', values);
      
      dispatch(postVoucher(values, props.router.navigate));
    },
  });

  const handleCpfCnpjChange = (event) => {
    const { value } = event.target;
    const maskedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    const formattedValue =
      maskedValue.length <= 11
        ? maskedValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1.$2.$3-") // CPF
        : maskedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1.$2.$3/$4-"); // CNPJ

    validation.setFieldValue("clientDocument", formattedValue);
  };
  
  const handleRecorrencyChange = (event) => {
    const { checked } = event.target;
    console.log('switch isMonthRecurring', event.target.checked)

    validation.setFieldValue("isMonthRecurring", checked);
  };

  const handleInputChange = (value) => {
    setVoucherAmount(value);

    validation.setFieldValue("amount", value);
  };
  
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  function toggleModalError() {
    setModalError(!modalError);
    setErrorMessage("");
    removeBodyCss();
  }

  useEffect(() => {
    if (error) {
      setErrorMessage(error);

      setModalError(true);

      setTimeout(() => {
        dispatch(resetApiError(null));
      }, 1000);
    }
  }, [dispatch, error]);

  return (
    <TabPane tabId="1" className="issueEnergyVoucherTab">
      <h2 className="title-tab_issueEnergyVoucher">1. Dados do Beneficiário</h2>

        <Form
          // onSubmit={validation.handleSubmit}
          validate={validation}
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
      <div>
          <FormGroup row>
                <Label
                  htmlFor="individual-issuance-email-address"
                  md="2"
                  className="col-form-label"
                >
                  Nome Completo
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="individual-issuance-email-address"
                    placeholder="Digite o Nome Completo"
                    name="clientName"
                    value={validation.values.clientName}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    maxLength={100}
                    invalid={
                      validation.touched.clientName && validation.errors.clientName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.clientName && validation.errors.clientName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.clientName}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
          <Row className="d-flex flex-row">
            <Col>
              <FormGroup row>
                <Label
                  htmlFor="individual-issuance-email-address"
                  md="4"
                  className="col-form-label"
                >
                  CPF/CNPJ
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="individual-issuance-email-address"
                    placeholder="Digite o CPF / CNPJ"
                    name="clientDocument"
                    value={validation.values.clientDocument}
                    onChange={handleCpfCnpjChange}
                    onBlur={validation.handleBlur}
                    maxLength={18}
                    invalid={
                      validation.touched.clientDocument && validation.errors.clientDocument
                        ? true
                        : false
                    }
                  />
                  {validation.touched.clientDocument && validation.errors.clientDocument ? (
                    <FormFeedback type="invalid">
                      {validation.errors.clientDocument}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup row>
                <Label
                  htmlFor="individual-issuance-email-address"
                  md="2"
                  className="col-form-label"
                >
                  Email
                </Label>
                <Col>
                  <Input
                    type="email"
                    id="individual-issuance-email-address"
                    placeholder="Digite o email"
                    name="clientEmail"
                    value={validation.values.clientEmail}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    maxLength={100}
                    invalid={
                      validation.touched.clientEmail && validation.errors.clientEmail
                        ? true
                        : false
                    }
                  />
                  {validation.touched.clientEmail && validation.errors.clientEmail ? (
                    <FormFeedback type="invalid">
                      {validation.errors.clientEmail}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
          </Row>
        
      </div>

      <hr className=" divider" />

      <div>
        <h2 className="title-tab_issueEnergyVoucher mt-2">2. Vale Energia</h2>
        <Row className="d-flex flex-row">
          <Col md={5}>
            <FormGroup className="form-check form-switch mb-3 form-switch_issueEnergyVoucher">
              <Label
                htmlFor="individual-issuance-monthly-recurrence"
                className="col-form-label"
              >
                Recorrência Mensal
              </Label>
              <input
                type="checkbox"
                className="form-check-input switch_issueEnergyVoucher"
                name="isMonthRecurring"
                onChange={handleRecorrencyChange}
                value={validation.values.isMonthRecurring}
                id="customSwitchsizemd"
              />
            </FormGroup>

            {
              validation.values.isMonthRecurring && (
                <FormGroup row>
                    <Label
                      htmlFor="individual-issuance-email-address"
                      md="4"
                      className="col-form-label"
                    >
                      Meses
                    </Label>
                    <Col md={4}>
                      <Input
                        type="number"
                        id="individual-issuance-number-recurrence"
                        placeholder=""
                        name="monthsToRecurring"
                        value={validation.values.monthsToRecurring}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.monthsToRecurring && validation.errors.monthsToRecurring
                            ? true
                            : false
                        }
                      />
                      {validation.touched.monthsToRecurring && validation.errors.monthsToRecurring ? (
                        <FormFeedback type="invalid">
                          {validation.errors.monthsToRecurring}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>
              )
            }
          </Col>
          <Col md={7}>
            <FormGroup className="recharge-value">
              <Label md="5">
                Valor do <br /> Vale Energia
              </Label>
              <div className="d-flex align-items-center px-3">
                <div className="recharge-value-symbol">R$</div>
                <NumericFormat
                        id="individual-issuance-credit-value"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        allowNegative={false}
                        fixedDecimalScale
                        // value={validation.values.amount}
                        // onChange={validation.handleChange}
                        value={voucherAmount}
                        onValueChange={({ value }) => handleInputChange(value)}
                      />
                      {/* <Input
                        type="text"
                        id="individual-issuance-voucher-value"
                        placeholder=""
                        name="amount"
                        value={validation.values.amount}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.amount && validation.errors.amount
                            ? true
                            : false
                        }
                      /> */}
                      {validation.touched.amount && validation.errors.amount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.amount}
                        </FormFeedback>
                      ) : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>

      <hr className="mt-0 divider" />

      <div className="card-energyVoucher_issueEnergyVoucher">
        <h2 className="title-tab_issueEnergyVoucher mb-2">
          3. Pré-visualização do Vale Energia
        </h2>

        <EnergyVoucher data={[
          {
            id: 1,
            value: voucherAmount,
            usedValue: 0,
            partnerLogoUrl: partner?.logoUrl
          }
        ]} />
      </div>

      <Row className="mt-4">
        <Button
          className="btn btn-issueIndividualEnergyVoucher"
          type="submit"
          disabled={loading}
        >
          {loading ? <Spinner /> : "Emitir Vale Energia"}
        </Button>
      </Row>
      </Form>
      <Modal
        className="modal-auth-page"
        size="sm"
        isOpen={modalError}
        toggle={() => {
          toggleModalError();
        }}
        centered
      >
        <div className="modal-body">
          <DangerousOutlinedIcon />

          <p>{errorMessage}</p>

          <Button
            onClick={() => {
              toggleModalError();
              // props.router.navigate('/');
            }}
          >
            Entendi
          </Button>
        </div>
      </Modal>
    </TabPane>
  );
};

export default withRouter(IndividualIssuance);
