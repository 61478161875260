import { Card, CardBody, CardTitle, Col, Progress, Row } from "reactstrap";

import "./CompanyRankingChart.styles.scss";

const CompanyRankingChart = () => {
  const data = [
    {
      name: "Empresa 1",
      percent: 78,
      color: "#556EE6",
    },
    {
      name: "Empresa 2",
      percent: 69,
      color: "#F1B44C",
    },
    {
      name: "Empresa 3",
      percent: 61,
      color: "#34C38F",
    },
  ];

  return (
    <Card className="CompanyRankingChart">
      <CardBody>
        <CardTitle>Ranking das Empresas - Top 3</CardTitle>

        <Row className="row-ranking-card align-items-center justify-content-end">
          <Col xl={2} className="ranking-card">
            <h3 className="title-ranking-card">Today</h3>
            <p className="value-ranking-card">1024</p>
          </Col>
          <Col xl={2} className="ranking-card">
            <h3 className="title-ranking-card">This month</h3>
            <p className="value-ranking-card">12356</p>
          </Col>
        </Row>

        <Row className="pt-5">
          {data.map((item) => (
            <div className="item-ranking-card">
              <div className="item-header-ranking-card">
                <p>{item.name}</p>
                <p>{item.percent}%</p>
              </div>
              <div className="animated-progess mb-4">
                <Progress
                  value={item.percent}
                  color={item.color}
                  className="progress-md"
                ></Progress>
              </div>
            </div>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default CompanyRankingChart;
