import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
  Spinner,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import {
  partnerDashboardSelector,
  resetApiError,
} from "slices/admin/credits/reducer";

import { fetchPartners, postCredits } from "slices/admin/credits/thunk";

import "./firstTab.styles.scss";
import { NumericFormat } from "react-number-format";

const customStylesNameSelect = {
  container: (provided) => ({
    ...provided,
    position: "relative",
    margin: 0,
    marginTop: 2,

    height: "2rem",
  }),
  control: (provided) => ({
    ...provided,
    height: "100%",
    paddingLeft: "1rem",
  }),

  menu: (provided) => ({
    ...provided,
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#333",
    padding: 8,
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  icon: {
    marginRight: 12,
  },
};

const FirstTab = ({ wizardformik, toggleTab, activeTab }) => {
  const dispatch: any = useDispatch();

  const { partners, error, errorMsg, loading, pixPayment } = useAppSelector(
    partnerDashboardSelector
  );

  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [creditValue, setCreditValue] = useState("");

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModalError() {
    setModalError(!modalError);
    setErrorMessage("");
    removeBodyCss();
  }

  const validation: any = useFormik({
    initialValues: {
      creditValue: "",
      partner: "",
    },
    validationSchema: Yup.object({
      creditValue: Yup.string().required(
        "Por favor, digite o valor do Credito."
      ),
      partner: Yup.string().required("Por favor, Selecione um Parceiro."),
    }),
    onSubmit: (values: any, { setFieldValue }) => {
      
      dispatch(
        postCredits({
          partner: { id: values.partner },
          // amount: values.creditValue,
          amount: Number(values.creditValue),
        })
      );
    },
  });

  useEffect(() => {
    dispatch(fetchPartners(null));
  }, []);

  useEffect(() => {
    if (pixPayment.id) toggleTab(2);
  }, [pixPayment]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);

      setModalError(true);

      setTimeout(() => {
        dispatch(resetApiError(null));
      }, 1000);
    }
  }, [dispatch, error]);

  const handleInputChange = (value) => {
    setCreditValue(value);

    validation.setFieldValue("creditValue", value);
  };

  return (
    <TabPane tabId={1}>
      <Form
        validate={validation}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        className="d-flex flex-column align-items-center"
      >
        <div className="first-tab-recharge">
          <Row>
            <Col>
              <FormGroup className="current-balance">
                <Label>Empresa Parceira</Label>
                {/* <p className="m-0 mt-2">R$ 1.406.278,00</p> */}
                <Col md={10}>
                  <Select
                    options={partners.map((partner) => ({
                      label: partner.fantasyName,
                      value: partner.id,
                    }))}
                    placeholder="Selecione"
                    name="partner"
                    styles={customStylesNameSelect}
                    // value={validation.values.creditValue}
                    // onChange={validation.handleChange}
                    onChange={(e: any) =>
                      validation.setFieldValue("partner", e.value)
                    }
                    components={{
                      SingleValue: ({ data, ...props }) => {
                        return <div {...props}>{data?.label}</div>;
                      },
                    }}
                  />
                </Col>
                {validation.touched.partner && validation.errors.partner ? (
                  <div className="mt-2">{validation.errors.partner}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col className="d-flex justify-content-end">
              <FormGroup className="recharge-value">
                <Label>Valor do Crédito</Label>
                <div className="d-flex align-items-center">
                  <div className="recharge-value-symbol">R$</div>
                  <NumericFormat
                    id="individual-issuance-credit-value"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale
                    value={creditValue}
                    onValueChange={({ value }) => handleInputChange(value)}
                  />
                  {/* <Input
                    type="text"
                    id="individual-issuance-credit-value"
                    placeholder=""
                    name="creditValue"
                    invalid={
                      validation.touched.creditValue &&
                      validation.errors.creditValue
                        ? true
                        : false
                    }
                  /> */}
                </div>
                {validation.touched.creditValue &&
                validation.errors.creditValue ? (
                  <div color="danger">{validation.errors.creditValue}</div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="resume-bills">
              <h3>Resumo da Fatura</h3>
              <div className="resume-bills-item">
                <p>Valor Solicitado</p>
                <p>
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parseFloat(validation.values.creditValue || "0"))}
                </p>
              </div>
              <div className="resume-bills-item">
                <p>Forma de Pagamento</p>
                <p>PIX</p>
              </div>
              <div className="resume-bills-item total">
                <p>Valor final da recarga</p>
                <p>
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parseFloat(validation.values.creditValue || "0"))}
                </p>
              </div>
            </Col>
          </Row>

          {/* <Row className="pt-5 text-start">
            <h4 className="mb-4">Métodos de Pagamento</h4>

            <SelectMethodPayment />
          </Row> */}

          <Row className="actions clearfix">
            <Col>
              <Button
                type="submit"
                disabled={loading}
                className={`
                ${
                  validation.errors.barcode
                    ? "button-continue-admin disabled"
                    : "button-continue-admin"
                }
                  mb-5
              `}
                // onClick={() => {
                //   toggleTab(activeTab + 1);
                // }}
              >
                {loading ? <Spinner /> : "Continuar"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <Modal
        className="modal-auth-page"
        size="sm"
        isOpen={modalError}
        toggle={() => {
          toggleModalError();
        }}
        centered
      >
        <div className="modal-body">
          <DangerousOutlinedIcon />

          <p>{errorMessage}</p>

          <Button
            onClick={() => {
              toggleModalError();
              // props.router.navigate('/');
            }}
          >
            Entendi
          </Button>
        </div>
      </Modal>
    </TabPane>
  );
};

export default FirstTab;
