import React from 'react';
import { Container } from 'reactstrap';
import Breadcrumb from 'Components/Common/Breadcrumb';

const PaymentHistory = () => {
  document.title = "Histórico | S Company";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Meu Vale" breadcrumbItem="Histórico" />
        </Container>
      </div>
    </React.Fragment >
  );
}

export default PaymentHistory;