import { postVoucherPartner, getBillDataClient, getBillData } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchBillToPaySuccess,
  postSuccess,
  fetchBillsSuccess,
  clearState
} from "./reducer";

export const fetchBillData = (barcode: any, toggleTab: any) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getBillDataClient({ barcode });

    console.log('aqui', response)
    dispatch(fetchBillToPaySuccess(response));
    toggleTab(2)
  } catch (error) {
    console.log("erro ao listar creditos", error);
    dispatch(apiError(error));
  }
}
  export const fetchBills = () => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getBillData(null);
    dispatch(fetchBillsSuccess(response));
  } catch (error) {
    console.log("erro ao listar creditos", error);
    dispatch(apiError(error));
    dispatch(clearState(null));
  }
};

export const postVoucher = (data: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());
    console.log('testaaa', data)
    const response = await postVoucherPartner(data);

    dispatch(postSuccess(response));
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};