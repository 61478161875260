import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createSelector } from "reselect";

import { Button, Card, CardBody, CardTitle, Spinner } from "reactstrap";

import EcommerceOrdersModal from "./EcommerceOrdersModal";
import TableContainer from "Components/Common/TableContainer";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { partnerDashboardSelector } from "slices/admin/credits/reducer";

import { fetchCredits } from "slices/admin/credits/thunk";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTransactionCol";

import "./transactionsTable.styles.scss";
import Receipt from "./Receipt";

const latestTransactionData = [
  {
    orderId: "#1",
    billingName: "Jacob Hunter",
    orderdate: "04 Oct, 2019",
    total: "392.12",
    paymentStatus: "CONFIRMED",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "PIX",
  },
  {
    orderId: "#2",
    billingName: "Ronald Taylor",
    orderdate: "04 Oct, 2019",
    total: "404.3",
    paymentStatus: "PENDING",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Vale",
  }
];

const LatestTransaction = ({ bills }: any) => {
  const dispatch: any = useDispatch();

  const [modal1, setModal1] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<any>("");
  const [modalDetails, setModalDetails] = useState<any>({});

  const { credits, loading } = useAppSelector(partnerDashboardSelector);

  const toggleViewModal = useCallback(
    (value: any) => {
      setModal1(!modal1);
      setEditDetails(value);
    },
    [modal1]
  );

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Empresa Parceira",
        accessor: "company",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Data",
        accessor: "createdAt",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Valor",
        accessor: "amount",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      // {
      //   Header: "Metodo Pagamento",
      //   accessor: "paymentMethod",
      //   disableFilters: true,
      //   Filter: false,
      //   Cell: (cellProps: any) => {
      //     return <PaymentMethod {...cellProps} />;
      //   },
      // },
      {
        Header: "Detalhes",
        disableFilters: true,
        accessor: "view",
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded btn-open-receipe"
              onClick={() => {
                setModalDetails(cellProps.cell.row.original)
                toggleViewModal(cellProps.cell.row.original)
              }}
            >
              {" "}
              Comprovante{" "}
            </Button>
          );
        },
      },
    ],
    [toggleViewModal]
  );

  return (
    <React.Fragment>
      {/* <Receipt isOpen={modal1} toggle={toggleViewModal} creditData={modalDetails} /> */}
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} creditData={modalDetails} />
      <Card className="transactions-table">
        <CardBody>
          <CardTitle tag="h4" className="mb-4">
            Histórico de Transações {loading && <Spinner size={"sm"} className="ml-4" />}
          </CardTitle>
          <TableContainer
            columns={columns}
            data={bills || []}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LatestTransaction;
