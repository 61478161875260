import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardTitle, Spinner } from "reactstrap";

// import UserEditModal from "./UserEditModal";
import TableContainer from "Components/Common/TableContainer";

import {
  OrderId,
  BillingName,
  BillingSocialName,
  Date,
  Total,
  PaymentStatus,
  BillingDocument,
} from "./LatestTransactionCol";

import "./transactionsTable.styles.scss";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { adminBackofficeSelector } from "slices/admin/backoffice/reducer";

import { fetchBils, updateBillStatus } from "slices/admin/backoffice/thunk";

import UserEditModal from "Components/partnerCompany/searchUser/SearchResults/UserEditModal";
import Receipt from "./Receipt";

const SearchResultsAdmin = () => {
  const [modal1, setModal1] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<any>("");
  const [modalDetails, setModalDetails] = useState<any>({});

  const dispatch: any = useDispatch();
  const { bills, loading } = useAppSelector(adminBackofficeSelector);

  const toggleViewModal = useCallback(
    (value: any) => {
      setModal1(!modal1);
      setEditDetails(value);
    },
    [modal1]
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Filter: false,
      //   Cell: (cellProps: any) => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Nome Cliente",
        accessor: "socialName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Email Cliente",
        accessor: "email",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingSocialName {...cellProps} />;
        },
      },
      {
        Header: "Documento",
        accessor: "document",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingDocument {...cellProps} />;
        },
      },
      {
        Header: "Criado Em",
        accessor: "createdAt",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Valor",
        accessor: "amount",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "PIX",
        accessor: "totalToPay",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <PaymentStatus value="Ativo" {...cellProps} />;
        },
      },
      {
        Header: "Apovar",
        disableFilters: true,
        accessor: "view",
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              disabled={loading || cellProps.cell.row.original.status === 'CONFIRMED'}
              onClick={() => {
                const newCredits:any = bills.map(credit => {
                  if(credit.id === cellProps.cell.row.original.id){
                    return {...credit, status: 'CONFIRMED'}
                  }

                  return credit;
                });

                // console.log('test', newCredits, cellProps.cell.row.original.id)
                dispatch(updateBillStatus(cellProps.cell.row.original.id, newCredits))
              }}
            >
              
              {cellProps.cell.row.original.status === 'CONFIRMED' ? 'Aprovado' : 'Aprovar'}
            </Button>
          );
        },
      },
    ],
    [toggleViewModal]
  );

  useEffect(() => {
    dispatch(fetchBils(null));
  }, []);

  return (
    <React.Fragment>
      <Receipt isOpen={modal1} toggle={toggleViewModal} creditData={modalDetails} />
      <Card className="transactions-table">
        <CardBody>
          <CardTitle tag="h4" className="mb-4">
            Pagamentos  {loading && <Spinner size='sm' />}
          </CardTitle>
          <TableContainer
            columns={columns}
            data={bills || []}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            isPagination={true}
            isShowingPageLength={true}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
            paginationDiv="col-sm-12 col-md-7"
            pagination="pagination justify-content-end pagination-rounded"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SearchResultsAdmin;
