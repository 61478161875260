import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import moment from 'moment';

const CheckBox = (cell: any) => {
  return cell.value ? cell.value : "";
};

const OrderId = (cell: any) => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  );
};

const BillingName = (cell: any) => {
  return cell?.cell?.row?.original?.user?.email || "";
};
const BillingSocialName = (cell: any) => {
  return cell?.cell?.row?.original?.user?.name || "";
};

const Date = (cell: any) => {
  moment.locale('pt-br'); 
  return cell.value ? moment(cell.value).locale('pt-br').format('DD/MM/YYYY') : "";
};

const Total = (cell: any) => {
  return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(cell?.value || '0'));
};

const PaymentStatus = (cell: any) => {
  return cell?.cell?.row?.original?.user?.document || "";
};
const PaymentMethod = (cell: any) => {
  return (
    <span>
      <i
        className={
          cell.value === "Paypal"
            ? "fab fa-cc-paypal me-1"
            : "" || cell.value === "COD"
            ? "fab fas fa-money-bill-alt me-1"
            : "" || cell.value === "Mastercard"
            ? "fab fa-cc-mastercard me-1"
            : "" || cell.value === "Visa"
            ? "fab fa-cc-visa me-1"
            : ""
        }
      />
      {cell.value}
    </span>
  );
};
export {
  CheckBox,
  OrderId,
  BillingName,
  BillingSocialName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
};
