import React, { useEffect, useState } from "react";

import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import EnergyVoucherSummary from "Components/admin/dashboard/EnergyVoucherSummary";
import TransactedVolumeChart from "Components/admin/reports/TransactedVolumeChart";
import EnergyVoucherOverview from "Components/admin/dashboard/EnergyVoucherOverview";
import TotalUsersAndPartnersChart from "Components/admin/dashboard/TotalUsersAndPartnersChart";
import CompanyRankingChart from "Components/admin/reports/CompanyRankingChart";

import "./reports.styles.scss";

const yearData = [
  {
    name: "Series A",
    data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18],
  },
];

const monthData = [
  {
    name: "Series A",
    data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
  },
  {
    name: "Series B",
    data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
  },
];

const weekData = [
  {
    name: "Series A",
    data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
  },
];

const ReportsAdmin = () => {
  document.title = "Relatórios | S Company";

  const reports = [
    {
      title: "Total Usuários",
      iconClass: "bx-copy-alt",
      description: "114,235",
    },
    {
      title: "Total Parceiros",
      iconClass: "bx-archive-in",
      description: "25",
    },
    {
      title: "Total Contas Pagas",
      iconClass: "bx-purchase-tag-alt",
      description: "213,235,391",
    },
  ];

  const [periodData, setPeriodData] = useState<any>([]);
  const [periodType, setPeriodType] = useState<string>("yearly");

  useEffect(() => {
    if (periodType === "weekly") {
      setPeriodData(weekData);
    }
    if (periodType === "monthly") {
      setPeriodData(monthData);
    }
    if (periodType === "yearly") {
      setPeriodData(yearData);
    }
  }, [periodType]);

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Relatórios" />

          <Row>
            <Col>
              <Row>
                {(reports || []).map((report: any, key: number) => (
                  <Col key={"_col_" + key}>
                    <Card className="mini-stats-wid card-reports">
                      <CardBody>
                        <div className="flex-grow-1">
                          <p className="title-card">{report.title}</p>
                          <h4 className="card-description">
                            <span>R$</span>
                            {report.description}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                <Col>
                  <EnergyVoucherSummary
                    periodData={periodData}
                    dataColors='["#495057", "#DEE1F3"]'
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={4}>
              <Card>
                <CardBody>
                  <CardTitle tag="h4" className="mb-4">
                    Visão geral Vale Energia
                  </CardTitle>
                  <EnergyVoucherOverview dataColors='["#DEE1F3", "#BFC2D2", "#495057"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <TransactedVolumeChart dataColors='["#556EE6", "#4CF1DD"]' />
            </Col>

            <Col lg={4}>
              <Card>
                <CardBody>
                  <TotalUsersAndPartnersChart dataColors='["#495057", "#DEE1F3"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <CompanyRankingChart />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportsAdmin;
