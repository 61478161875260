import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Form, Row, TabPane } from "reactstrap";

import SelectMethodPayment from "Components/finalClient/selectMethodPayment";

import EnelLogo from "./enel_logo.svg";
import YamanLogo from "./logo_yaman.svg";

import "./secondTab.styles.scss";

const SecondTab = ({ toggleTab, activeTab, billToPay, myVouchers }) => {
  return (
    <TabPane tabId={2}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Form className="second-tab-payment col-xl-8">
          <h2>
            Você está economizando <span>{Number((billToPay?.balance / billToPay?.amount)* 100) < 100 ? Number((billToPay?.balance / billToPay?.amount)* 100).toFixed(2) : 100}%</span>
            <i className="mdi mdi-information-outline"></i>
          </h2>

          <Row className="w-100 pt-3 text-start">
            <h4>Parceiros e saldos</h4>

            <div className="card-partners-and-balances">
              <div className="d-flex col">
                <div className="card-partners-and-balances_logo">
                  <img src={ myVouchers[0]?.partnerLogoUrl } style={{width: '100px'}} alt="Logo do Parceiro" />
                </div>
                <div className="card-partners-and-balances_name">
                  <p>{ myVouchers[0]?.partnerName || '' }</p>
                </div>
              </div>

              <div className="card-partners-and-balances_value">{Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parseFloat(billToPay?.balance || "0"))}</div>
            </div>

          </Row>

          <Row className="w-100 gap-2 pt-3 text-start">
            <h4 className="mb-4">Resumo da Fatura</h4>

            <div className="card-invoice-summary">
              <p>Beneficiário</p>
              <p>{billToPay?.company}</p>
            </div>
            <div className="card-invoice-summary">
              <p>Valor da conta de luz</p>
              <p>{Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parseFloat(billToPay?.amount || "0"))}</p>
            </div>
            {/* <div className="card-invoice-summary">
              <p>Descontos</p>
              <p> -R$19,00</p>
            </div> */}
            <div className="card-invoice-summary">
              <p>Forma de Pagamento</p>
              <p>{billToPay.valorPagar > 0 ? 'Vale Energia + PIX' : 'Vale Energia'}</p>
            </div>

            <div className="card-invoice-total">
              <p>Total a pagar:</p>
              <p>{Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parseFloat(billToPay?.totalToPay || "0"))}</p>
            </div>
          </Row>

          {/* <Row className="w-100 pt-3 text-start">
            <h4 className="mb-4">Métodos de Pagamento</h4>

            <SelectMethodPayment />
          </Row> */}

          <div className="actions clearfix w-100 second-tab-payment_actions">
            <Link
              to="#"
              className="previous w-100"
              onClick={() => {
                toggleTab(activeTab - 1);
              }}
            >
              Voltar
            </Link>
            <Link
              to="#"
              className="button-continue w-100"
              onClick={() => {
                toggleTab(activeTab + 1);
              }}
            >
              Pagar
            </Link>
          </div>
        </Form>
      </div>
    </TabPane>
  );
};

export default SecondTab;
