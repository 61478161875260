import React from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../ChartDynamicColor";

const EnergyVoucherOverview = ({ dataColors }: any) => {
  const dountApexChartColors = getChartColorsArray(dataColors);

  const series = [55, 0, 30];
  const options: any = {
    labels: ["Emitidos", "Expirados", "Usados"],
    colors: dountApexChartColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="370"
    />
  );
};

export default EnergyVoucherOverview;
