import { getClientDashboard } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchSuccess,
  clearState,
} from "./reducer";

export const fetchData = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getClientDashboard(null);

    /**
     * [ ] - validar quantidade de vouchers
     * [X] - validar se o saldo é maior que primeiro voucher 
     * [ ] - se o saldo for maior que o primeiro, mostrar o segundo voucher 
     * [X] - se o saldo for menor que o primeiro, mostrar somente o primeiro e mostrar valor usado 
     */

    // myVouchers balance

    function fnSortVoucherByDate(a: {createdAt: string}, b: {createdAt: string}) {
      const dataA = new Date(a.createdAt);
      const dataB = new Date(b.createdAt);
      return dataB.getTime() - dataA.getTime();
      return 1
    }
    
    if (response.balance.value < response.myVouchers[0].value) {
      response.myVouchers = response.myVouchers.sort(fnSortVoucherByDate);
      response.myVouchers[0].valorRestante = response.myVouchers[0].value - response.balance.value;
    }

    console.log('test voucher calc', response.myVouchers)

    dispatch(fetchSuccess(response));
  } catch (error) {
    console.log("erro ao fazer login", error);
    dispatch(clearState(null));
    dispatch(apiError(error));
  }
};

