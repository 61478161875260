import React from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import ReactInputMask from "react-input-mask";

import {
  Container,
} from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import SearchResults from "Components/admin/backoffice/SearchResults";

import "./SearchEnergyVoucher.styles.scss";

const BackofficeAdmin = () => {
  document.title = "Backoffice | S Company";

  const validation: any = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values: any) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Backoffice" />

          

          <SearchResults />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BackofficeAdmin;
