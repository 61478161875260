import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classNames from "classnames";

import Breadcrumb from "Components/Common/Breadcrumb";
import StackedColumnChart from "Components/finalClient/historic/graphic";
import LatestTransaction from "Components/finalClient/historic/latestTransactions";
import MonthlyEarning from "Components/finalClient/historic/monthlyEarning";
import { useAppDispatch, useAppSelector } from "slices";
import { clientDashboardSelector } from "slices/client/dashboard/reducer";
import { clientBillSelector } from "slices/client/bill/reducer";
import { fetchData } from "slices/client/dashboard/thunk";

import "./historic.styles.scss";
import { fetchBills } from "slices/client/bill/thunk";

const yearData = [
  {
    name: "Series A",
    data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18],
  },
];

const monthData = [
  {
    name: "Series A",
    data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
  },
  {
    name: "Series B",
    data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
  },
];

const weekData = [
  {
    name: "Series A",
    data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
  },
];

const Historic = () => {
  document.title = "Histórico | S Company";
  const dispatch: any = useAppDispatch();
  const { balance, myVouchers, totalCounters, simpleTransactionHistory } = useAppSelector(clientDashboardSelector)
  const { bills, loading } = useAppSelector(clientBillSelector)

  const reports = [
    // {
    //   title: "Total Economizado",
    //   iconClass: "bx-copy-alt",
    //   description: "114.235,02",
    // },
    {
      title: "Saldo",
      iconClass: "bx-archive-in",
      isMoney: true,
      description: "235,02",
    },
    {
      title: "Vales Recebidos",
      iconClass: "bx-purchase-tag-alt",
      isMoney: false,
      description: "4",
    },
    {
      title: "Contas Pagas",
      iconClass: "bx-purchase-tag-alt",
      isMoney: false,
      description: "1",
    },
  ];

  const [periodData, setPeriodData] = useState<any>([]);
  const [periodType, setPeriodType] = useState<string>("yearly");

  useEffect(() => {
    if (periodType === "weekly") {
      setPeriodData(weekData);
    }
    if (periodType === "monthly") {
      setPeriodData(monthData);
    }
    if (periodType === "yearly") {
      setPeriodData(yearData);
    }
  }, [periodType]);

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
  };

  useEffect(() => {
    dispatch(fetchBills())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="historic-page">
          {/* <Breadcrumb title="Painel do Usuário" breadcrumbItem="Histórico" /> */}
          <Row>
            <Col xl={12}>
              <Row>
                {/* Reports Render */}
                  <Col md={4}>
                    <Card className="mini-stats-wid card-historic">
                      <CardBody>
                        <div className="flex-grow-1">
                          <p className="title-card">Saldo</p>
                          <h4 className="card-description">
                            {/* <span>R$</span> */}
                            {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(balance.value) || 0)}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx bx-archive-in font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mini-stats-wid card-historic">
                      <CardBody>
                        <div className="flex-grow-1">
                          <p className="title-card">Vales Recebidos</p>
                          <h4 className="card-description">
                            {/* <span>R$</span> */}
                            {myVouchers.length}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx bx-purchase-tag-alt font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mini-stats-wid card-historic">
                      <CardBody>
                        <div className="flex-grow-1">
                          <p className="title-card">Contas Pagas</p>
                          <h4 className="card-description">
                            {/* <span>R$</span> */}
                            {/* pegar a lista de transações e filtrar por pagas */}
                            {bills && bills[0] && bills.filter(b => b.status === 'CONFIRMED').length || 0}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx bx-purchase-tag-alt font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <LatestTransaction bills={bills} loading={loading} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Historic;
