import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, Row, Spinner, TabPane } from "reactstrap";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { partnerDashboardSelector, resetApiError } from "slices/admin/credits/reducer";
import { authSelector } from "slices/auth/reducer";

import { postCredits } from "slices/admin/credits/thunk";

import { NumericFormat } from "react-number-format";

import "./firstTab.styles.scss";

const FirstTab = ({ wizardformik, toggleTab, activeTab }) => {
  const dispatch: any = useDispatch();

  const { pixPayment, error, errorMsg, loading } = useAppSelector(partnerDashboardSelector);
  const { partner } = useAppSelector(authSelector);

  const [creditValue, setCreditValue] = useState("");
  const [feePercent, setFeePercent] = useState(20);
  const [creditFee, setCreditFee] = useState("");
  const [creditValueWithFee, setCreditValueWithFee] = useState("");
  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModalError() {
    setModalError(!modalError);
    setErrorMessage("");
    removeBodyCss();
  }

  const validation: any = useFormik({
    initialValues: {
      creditValue: "",
      // partner: "",
    },
    validationSchema: Yup.object({
      creditValue: Yup.string().required("Por favor, digite o valor do Credito."),
      // partner: Yup.string().required("Por favor, Selecione um Parceiro."),
    }),
    onSubmit: (values: any) => {
      // console.log(values)

      console.log('on submit', values)
      dispatch(postCredits({
        partner: { id: partner.id },
        amount: Number(values.creditValue)
      }));
    },
  });

  const handleInputChange = (value) => {
    setCreditValue(value);

    validation.setFieldValue("creditValue", value);
  };

  useEffect(() => {
    if (pixPayment.id) toggleTab(2)    
  }, [pixPayment])

  useEffect(() => {
    if (error) {
      setErrorMessage(error);

      setModalError(true);

      setTimeout(() => {
        dispatch(resetApiError(null));
      }, 1000);
    }
  }, [dispatch, error]);

  useEffect(() => { console.log('partiner', partner) }, [partner])

  useEffect(() => {
    if (Number(creditValue) > 0) {
      const calculatedFee = Number(creditValue) + (Number(creditValue) * feePercent / 100)
      const fee = (Number(creditValue) * feePercent / 100)
      // console.log('calculatedFee', calculatedFee)
      setCreditFee(fee.toFixed(2))
      setCreditValueWithFee(calculatedFee.toFixed(2))
    } else {
      setCreditFee("0")
      setCreditValueWithFee("0")
    }
  }, [creditValue])
  return (
    <TabPane tabId={1}>
      <Form
        validate={validation}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        className="d-flex flex-column align-items-center"
      >
        <div className="first-tab-recharge">
          <Row>
            {/* <Col>
              <FormGroup className="current-balance">
                
              </FormGroup>
            </Col> */}
            <Col md={4}>
              <FormGroup className="recharge-value">
                <Label>Valor do Crédito</Label>
                <div className="d-flex align-items-center">
                  <div className="recharge-value-symbol">R$</div>
                  <NumericFormat
                    id="individual-issuance-credit-value"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale
                    value={creditValue}
                    onValueChange={({ value }) => handleInputChange(value)}
                  />
                  {/* <Input
                    type="text"
                    id="individual-issuance-credit-value"
                    placeholder=""
                    name="creditValue"
                    value={validation.values.creditValue}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.creditValue && validation.errors.creditValue
                        ? true
                        : false
                    }
                  /> */}
                  {/* {validation.touched.creditValue && validation.errors.creditValue ? (
                    <FormFeedback type="invalid">
                      {validation.errors.creditValue}
                    </FormFeedback>
                  ) : null} */}
                </div>
                {validation.touched.creditValue &&
                validation.errors.creditValue ? (
                  <div style={{ color: 'red' }}>{validation.errors.creditValue}</div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row className="resume-bills">
            <h3>Resumo da Fatura</h3>
            <div className="resume-bills-item">
              <p>Valor Solicitado</p>
              <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(creditValue || '0'))}</p>
            </div>
            <div className="resume-bills-item">
              <p>Taxa Plataforma</p>
              <p>{feePercent}% {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(creditFee || '0'))}</p>
            </div>
            <div className="resume-bills-item">
              <p>Forma de Pagamento</p>
              <p>PIX</p>
            </div>
            <div className="resume-bills-item total">
              <p>Valor final da recarga</p>
              <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(creditValueWithFee || '0'))}</p>
            </div>
          </Row>

          {/* <Row className="pt-5 text-start">
            <h4 className="mb-4">Métodos de Pagamento</h4>

            <SelectMethodPayment />
          </Row> */}

          <Row className="actions clearfix">
            <Button
              type="submit"
              disabled={loading}
              className={`
                ${wizardformik.errors.barcode
                  ? "button-continue disabled"
                  : "button-continue"}
                  mb-5
              `
              }
              // onClick={() => {
              //   toggleTab(activeTab + 1);
              // }}
            >
              {loading ? <Spinner /> : "Continuar"}
            </Button>
          </Row>
        </div>
      </Form>
      <Modal
          className="modal-auth-page"
          size="sm"
          isOpen={modalError}
          toggle={() => {
            toggleModalError();
          }}
          centered
        >
          <div className="modal-body">
            <DangerousOutlinedIcon />

            <p>{errorMessage}</p>

            <Button
              onClick={() => {
                toggleModalError();
                // props.router.navigate('/');
              }}
            >
              Entendi
            </Button>
          </div>
        </Modal>
    </TabPane>
  );
};

export default FirstTab;
