import * as Yup from "yup";
import { useFormik } from "formik";

import { useDispatch } from "react-redux";

import { useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

import { updateUser } from "slices/auth/thunk";

import {
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Spinner,
} from "reactstrap";

const PersonalInformation = () => {
  const dispatch: any = useDispatch();

  const { error, loading, userId } = useAppSelector(authSelector);

  const validation: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      documents: "",
      address: "",
      country: "",
      states: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, digite seu nome."),
      email: Yup.string().email().required("Por favor, digite seu email."),
      phone: Yup.string().required("Por favor, digite seu telefone."),
    }),
    onSubmit: (values: any) => {
      const body = {
        userId,
        status: {
          id: 1,
          name: "Active",
        },
        name: values.name,
        document: values.document,
        phone: values.phone,
        addressZipCode: values.cep,
        addressCity: values.city,
        addressStreet: values.address,
        addressStreetNumber: values.addressNumber,
        addressComplement: values.complement,
        addressState: values.state,
        password: values.password,
      };

      dispatch(updateUser(body, null));
    },
  });

  return (
    <TabPane tabId="1">
      <div>
        <Form onSubmit={validation.handleSubmit}>
          <FormGroup className="mb-4" row>
            <Label htmlFor="billing-name" md="2" className="col-form-label">
              Nome
            </Label>
            <Col md={10}>
              <Input
                type="text"
                id="billing-name"
                placeholder="Digite seu nome"
                name="name"
                value={validation.values.name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label
              htmlFor="billing-email-address"
              md="2"
              className="col-form-label"
            >
              Email
            </Label>
            <Col md={10}>
              <Input
                type="email"
                id="billing-email-address"
                placeholder="Digite seu email"
                name="email"
                value={validation.values.email}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label htmlFor="billing-phone" md="2" className="col-form-label">
              Telefone
            </Label>
            <Col md={10}>
              <Input
                type="number"
                id="billing-phone"
                placeholder="Digite seu número de telefone"
                name="phone"
                value={validation.values.phone}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.phone && validation.errors.phone
                    ? true
                    : false
                }
              />
              {validation.touched.phone && validation.errors.phone ? (
                <FormFeedback type="invalid">
                  {validation.errors.phone}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <div className="mt-4">
            <button className="btn btn-save-perfil">
              {loading ? <Spinner /> : "Salvar"}
            </button>
          </div>
        </Form>
      </div>
    </TabPane>
  );
};

export default PersonalInformation;
