import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
  Modal,
  Button,
} from "reactstrap";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { resetPassword, resetLoginMsgFlag } from "slices/auth/thunk";

import ImageAuth from "../../assets/images/auth-bg.png";
import Logo from "../../assets/images/logo/scompany-logo-black.png";

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Resetar Senha | S Company";
  
  const queryParameters = new URLSearchParams(window.location.search)
  const hashToken = queryParameters.get("hash")

  const dispatch = useDispatch<any>();

  const { error, loading, emailResetSucess } = useAppSelector(authSelector);

  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModalError() {
    setModalError(!modalError);
    setErrorMessage("");
    removeBodyCss();
  }

  // Form validation
  const validation: any = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Por favor, digite a sua senha."),
      confirmPassword: Yup.string().required("Por favor, confirme a sua senha."),
    }),
    onSubmit: (values: any) => {
      dispatch(resetPassword({
        password: values.password,
        hash: hashToken
      }));
    },
  });

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      setModalError(true);

      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 1000);
    }
  }, [dispatch, error]);
  
  useEffect(() => {
    dispatch(resetLoginMsgFlag());
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages">
        <Row>
          <Col className="container-left-auth d-none d-lg-flex">
            <img src={ImageAuth} alt="Duas pessoas dando risada" />
          </Col>
          <Col xl={3} className="container-right-auth">
            <div className="w-100 h-100">
              <Row>
                <div className="text-primary p-4 d-flex justify-content-center logo-header">
                  <img src={Logo} alt="" />
                </div>
              </Row>

              <Row className="p-3 form-auth">

                { 
                  !emailResetSucess && <div>
                    <div>
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >                      
                        <div className="d-flex gap-2 mb-3">
                          <Col>
                            <Label className="form-label">Senha*</Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Senha"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                              disabled={loading}
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Label className="form-label">Confirmar Senha*</Label>
                            <Input
                              name="confirmPassword"
                              className="form-control"
                              placeholder="Confirmar Senha"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmPassword || ""}
                              invalid={
                                validation.touched.confirmPassword &&
                                validation.errors.confirmPassword
                                  ? true
                                  : false
                              }
                              disabled={loading}
                            />
                            {validation.touched.confirmPassword &&
                            validation.errors.confirmPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirmPassword}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block btn-auth"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <Spinner /> : "Resetar Senha"}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                }

                {
                  emailResetSucess && <div className="mt-3 text-center reset-password-success">
                  <div>
                    <i className="bx bx-mail-send" />
                  </div>

                  <p className="title">Senha alterada com sucesso.</p>
                  <p className="details">
                    A senha foi atualizada conforme solicitado.
                  </p>
                  <Link className="btn button-back-to-login" to="/">
                    <i className="bx bx-chevron-left" />
                    Voltar para Login
                  </Link>
                </div>
                }                
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
          className="modal-auth-page"
          size="sm"
          isOpen={modalError}
          toggle={() => {
            toggleModalError();
          }}
          centered
        >
          <div className="modal-body">
            <DangerousOutlinedIcon />

            <p>{errorMessage}</p>

            <Button
              onClick={() => {
                toggleModalError();
                // props.router.navigate('/');
              }}
            >
              Entendi
            </Button>
          </div>
        </Modal>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
