import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
    pixPayment: {
      amount: null,
      partner: {
        id: null
      },
      deletedAt: null,
      id: null,
      qrCode: {
        payload: '',
        base64: '',
      },
      createdAt: '',
      updatedAt: '',
    },
    partners: [
    {
        id: null,
        socialName: '',
        fantasyName: '',
        addressStreet: '',
        addressStreetNumer: '',
        addressZipCode: '',
        addressNeighborhood: '',
        addressCity: '',
        logoUrl: '', // URL da Logo da empresa parceiro
        addressState: '',
        addressComplement: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: null
    },
    ],
    credits: [
      {
        id: null,
        amount: null,
        createdAt: "",
        updatedAt: "",
        deletedAt: null,
        partner: {
          id: null,
          socialName: "",
          fantasyName: "",
          addressStreet: "",
          addressStreetNumer: "",
          addressZipCode: "",
          addressNeighborhood: "",
          addressCity: "",
          addressState: "",
          addressComplement: "",
          createdAt: "",
          updatedAt: "",
          deletedAt: null,
        },
      },
    ],

  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const adminDashboardSlice = createSlice({
  name: "adminCredit",
  initialState,
  reducers: {
    fetchSuccess(state, action) {
      // User data
      // state.balance = action.payload.balance;
      // state.totalCounters = action.payload.totalCounters;
      // state.simpleTransactionHistory = action.payload.simpleTransactionHistory;
      // state.myVouchers = action.payload.myVouchers;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    
    fetchPatnersSuccess(state, action) {
      // User data
      state.partners = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    fetchCreditsSuccess(state, action) {
      // User data
      state.credits = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    
    postPatnersSuccess(state, action) {
      // User data
      state.pixPayment = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    
    resetPixSuccess(state, action) {
      // User data
      state.pixPayment = initialState.pixPayment;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },

    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    
    resetApiError(state, action) {
      state.error = '';
      state.loading = false;
      state.errorMsg = false;
    },

    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  apiError,
  isFetching,
  fetchSuccess,
  fetchPatnersSuccess,
  fetchCreditsSuccess,
  postPatnersSuccess,
  resetApiError,
  resetPixSuccess,
} = adminDashboardSlice.actions;

export const partnerDashboardSelector = (state: RootState) => state.AdminCredit;

export default adminDashboardSlice.reducer;