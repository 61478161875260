import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
    balance: {
    value: '', // saldo carteira
    },
    totalCounters: {
        totalBillsPaid: null, // Total de contas pagas
        totalBillsSaved: '', // Total em dinheiro economizado em contas
    },
    simpleTransactionHistory: [],
    myVouchers: [],

  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const partnerDashboardSlice = createSlice({
  name: "partnerDashboard",
  initialState,
  reducers: {
    fetchSuccess(state, action) {
      // User data
      state.balance = action.payload.balance;
      state.totalCounters = action.payload.totalCounters;
      state.simpleTransactionHistory = action.payload.simpleTransactionHistory;
      state.myVouchers = action.payload.myVouchers;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },

    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  apiError,
  isFetching,
  fetchSuccess,
  clearState,
} = partnerDashboardSlice.actions;

export const partnerDashboardSelector = (state: RootState) => state.PartnerDashboard;

export default partnerDashboardSlice.reducer;