import React, { useEffect, useState } from "react";

import { Col } from "reactstrap";

import Logo from "./logo.svg";

import Leaf from "assets/images/psychiatry.svg";

import "./energyVoucher.styles.scss";

interface EnergyVoucherProps {
  data?: any;
  currentBalance?: any;
}

const EnergyVoucher = ({ data, currentBalance }: EnergyVoucherProps) => {
  const [usedValueGeral, setUsedValueGeral] = useState("");

  useEffect(() => {
    setUsedValueGeral(
      (Number(data[0]?.value) - Number(currentBalance || 0)).toFixed(2)
    );
    console.log("testt33t", data[0].logoUrl);
    console.log(
      "testt33tww",
      // voucher?.usedValue || usedValueGeral || "0"
      (Number(data[0]?.value) - Number(currentBalance || 0)).toFixed(2)
    );
    console.log(
      "testt33t",
      `${
        data[0]?.value && data[0]?.usedValue
          ? (data[0]?.usedValue / data[0]?.value || 0) * 100
          : 0
      }%`
    );
  }, [currentBalance, data]);
  return (
    <React.Fragment>
      {data && data.length > 0 ? (
        data.map((voucher) => (
          <div
            className="w-layout-blockcontainer container w-container"
            key={voucher.id}
          >
            <div className="voucher-sm-card">
              <div className="voucher-sm-card-content">
                <div className="voucher-sm-logo">
                  <img
                    src={voucher.partnerLogoUrl}
                    loading="lazy"
                    alt=""
                    className="voucher-sm-image"
                  />
                </div>
                <div className="voucher-sm-details">
                  <div className="voucher-sm-amount">
                    <span className="voucher-sm-currency">R$</span>
                    <span className="voucher-sm-value">
                      {Intl.NumberFormat("pt-BR", {
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }).format(parseFloat(voucher?.value || "0"))}
                    </span>
                  </div>
                  <div className="div-block-3">
                    <div
                      className="div-block-3-copy"
                      style={{
                        width: `${
                          voucher?.value &&
                          (voucher?.usedValue || usedValueGeral)
                            ? ((voucher?.usedValue || usedValueGeral) /
                                voucher?.value || 0) * 100
                            : 0
                        }%`,
                      }}
                      // style={{ width: `${(voucher?.value && voucher?.usedValue) ? ((voucher?.usedValue / voucher?.value || 0) * 100) : 0}%` }}
                    ></div>
                  </div>
                  <div className="voucher-sm-utilized">
                    Utilizado{" "}
                    <span className="voucher-sm-utilized-value">
                      {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(
                        parseFloat(voucher?.usedValue || usedValueGeral || "0")
                      )}
                    </span>
                  </div>
                  <div className="voucher-sm-expiration">Expira em 2 dias</div>
                </div>
              </div>
            </div>
            <div className="vale-a">
              <div
                id="w-node-_15463c37-9280-75c2-e773-124548567fa6-2f60d75a"
                className="w-layout-layout quick-stack wf-layout-layout"
              >
                <div
                  id="w-node-be0beeba-c465-0f8a-a5c9-4ae234baf05d-2f60d75a"
                  className="w-layout-cell cell-2"
                >
                  <div className="heading-only-desktop">
                    <h1 className="heading-4">Vale Energia</h1>
                    <div className="text-block-copy">Patrocinado por:</div>

                    <img
                      src={voucher.partnerLogoUrl}
                      loading="lazy"
                      alt=""
                      className="image"
                    />
                  </div>
                  <div className="second-row">
                    <div className="div-block">
                      <h1 className="heading-2-copy">
                        <span className="text-span">R$</span>
                        {Intl.NumberFormat("pt-BR", {
                          currency: "BRL",
                          minimumFractionDigits: 2,
                        }).format(parseFloat(voucher?.value || "0"))}
                      </h1>
                      <div className="div-block-2">
                        <div className="text-block">Saldo Utilizado</div>
                        <h1 className="heading-3">
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(
                            parseFloat(
                              voucher?.usedValue || usedValueGeral || "0"
                            )
                          )}
                        </h1>
                      </div>
                      <div className="div-block-3">
                        <div
                          className="div-block-3-copy"
                          style={{
                            width: `${
                              voucher?.value &&
                              (voucher?.usedValue || usedValueGeral)
                                ? ((voucher?.usedValue || usedValueGeral) /
                                    voucher?.value || 0) * 100
                                : 0
                            }%`,
                          }}
                          // style={{ width: `${(voucher?.value && voucher?.usedValue) ? ((voucher?.usedValue / voucher?.value || 0) * 100) : 0}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-b00d8cc9-e82a-1eaa-438d-42d1dd9c4b16-2f60d75a"
                  className="w-layout-cell cell"
                >
                  <div className="image-header">
                    <img src="/assets/logo-sm-scompany-top.png" />
                  </div>
                  <div className="second-column">
                    <h1 className="heading">Saldo Total</h1>
                    <h1 className="heading-2">
                      <span className="text-span">R$</span>
                      {Intl.NumberFormat("pt-BR", {
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }).format(parseFloat(voucher?.value || "0"))}
                    </h1>
                    <span className="warning-text">
                      PARA VOCÊ PAGAR SUA CONTA DE LUZ
                    </span>

                    <p className="info-text">
                      Como o Vale Energia transforma: Menos dióxido de carbono
                      (CO2) na atmosfera, redução no aquecimento global e
                      impacto social para colaboradores, parceiros e clientes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="empty-energy-voucher">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M440-120v-319q-64 0-123-24.5T213-533q-45-45-69-104t-24-123v-80h80q63 0 122 24.5T426-746q31 31 51.5 68t31.5 79q5-7 11-13.5t13-13.5q45-45 104-69.5T760-720h80v80q0 64-24.5 123T746-413q-45 45-103.5 69T520-320v200h-80Zm0-400q0-48-18.5-91.5T369-689q-34-34-77.5-52.5T200-760q0 48 18 92t52 78q34 34 78 52t92 18Zm80 120q48 0 91.5-18t77.5-52q34-34 52.5-78t18.5-92q-48 0-92 18.5T590-569q-34 34-52 77.5T520-400Zm0 0Zm-80-120Z" />
          </svg>

          <p>Nenhum Vale Energia Cadastrado.</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default EnergyVoucher;
