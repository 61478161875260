import { getParnterDashboard, getParntersAdmin, postCredtisAdmin, getCreditsAdmin } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchSuccess,
  fetchPatnersSuccess,
  fetchCreditsSuccess,
  postPatnersSuccess,
} from "./reducer";

export const fetchData = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getParnterDashboard(null);

    dispatch(fetchSuccess(response));
  } catch (error) {
    console.log("erro ao fazer buscar dados", error);
    dispatch(apiError(error));
  }
};


export const fetchPartners = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getParntersAdmin(null);

    dispatch(fetchPatnersSuccess(response));
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

export const fetchCredits = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getCreditsAdmin(null);

    dispatch(fetchCreditsSuccess(response));
  } catch (error) {
    console.log("erro ao listar creditos", error);
    dispatch(apiError(error));
  }
};

export const postCredits = (data: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());
    console.log('testaaa', data)
    const response = await postCredtisAdmin(data);

    dispatch(postPatnersSuccess(response));
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

