import React, { useEffect } from "react"
import withRouter from "Components/Common/withRouter";
import { Navigate } from "react-router-dom";

import { logoutUser,  } from "../../slices/thunk";
import { clearState as dashboardClearState } from "../../slices/client/dashboard/reducer";
import { clearState as billClearState }  from "../../slices/client/bill/reducer";

// import {}  from "../../slices/admin/backoffice/reducer";
// import {cle}  from "../../slices/admin/credits/reducer";
import { clearState as adminDashboarClearState }  from "../../slices/admin/dashboard/reducer";
import { clearState as adminPartnerClearState }  from "../../slices/admin/partner/reducer";

//redux
import { useAppDispatch, useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

const Logout = (props: any) => {
  const dispatch: any = useAppDispatch();
  const { isUserLogged } = useAppSelector(authSelector);

  useEffect(() => {
    dispatch(dashboardClearState(null));
    dispatch(billClearState(null));
    dispatch(adminDashboarClearState(null));
    dispatch(adminPartnerClearState(null));

    dispatch(logoutUser(props.router.navigate));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isUserLogged) {
    return <Navigate to="/login" />;
  }

  return <></>;
}


export default withRouter(Logout)
