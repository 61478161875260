import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import withRouter from "../../Common/withRouter";

import "./profileMenu.styles.scss";

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState(false);

  const { name, role } = useAppSelector(authSelector);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block dropdown-menu-user"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <i className="bx bx-caret-down" /> */}
          <span className="d-xl-inline-block ms-2 me-1 username">
            {name || "user"}
          </span>
          <i className="mdi mdi-chevron-down d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href={role === 'Partner' ? '/partnerCompany/perfil' :"/profile"}>
            {" "}
            <i className="bx bx-user align-middle me-1" />
            Perfil
          </DropdownItem>
          {/* <DropdownItem
            tag="a"
            href={process.env.PUBLIC_URL + "/client/historic"}
          >
            <i className="mdi mdi-equalizer align-middle me-1 white" />
            {props.t("Histórico")}
          </DropdownItem> */}
          {/* <DropdownItem
            tag="a"
            href={process.env.PUBLIC_URL + "/client/terms-of-use"}
          >
            {props.t("Termos de Uso")}
          </DropdownItem> */}
          {/* <DropdownItem
            tag="a"
            href={process.env.PUBLIC_URL + "/client/support"}
          >
            {props.t("Suporte")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off align-middle me-1 text-danger" />
            <span>{props.t("Sair da Conta")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));
