import axios from "axios";
import { isAfter } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import store from "../slices/index"
import { logoutUser } from "slices/auth/thunk";

// default
axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:3003";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
// content type
// let authUser: any = (localStorage.getItem("authUser"));

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data || response;
  },

  function (error: any) {
    const { statusCode, errors } = error.response?.data || {};

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const statusCodeMessages = {
      500: "A operação não pode ser concluída, tente novamente em breve!",
      401: "Credenciais inválidas",
      404: "Desculpe! Não foi possível encontrar",
    };

    if (statusCode === 401 && error?.config?.url === '/api/v1/auth/refresh') {
      console.log('Erro no refresh token, deslogando')
      window.location.pathname = '/logout'
    }

    const message = statusCodeMessages[statusCode] || errors?.message || error.message || error;

    return Promise.reject(message);
  }
);

axios.interceptors.request.use(
  (config) => {
    const { Auth: authData } = store.getState()

    if (authData?.tokenExpires && 
        authData?.token && 
        isAfter(new Date(), fromZonedTime(authData?.tokenExpires, 'America/Sao_Paulo')) &&
        !['/api/v1/auth/email/login', 
          '/api/v1/auth/email/register', 
          '/api/v1/auth/email/confirm',
          '/api/v1/auth/forgot/password',
          '/api/v1/auth/reset/password',
          '/api/v1/auth/send-invitation',
          '/api/v1/auth/refresh',
        ].includes(config?.url || 'n/a')
    ) {
      console.log('token expirado, iniciando refresh token', authData)
      return axios.post('/api/v1/auth/refresh', { "refreshToken": authData?.refreshToken})
      .then(response => { 
        config.headers.Authorization = `Bearer ${response?.data?.access?.token}`;
        // store.dispatch()
        return config
      })
    } else if (!!authData?.token) {
      config.headers.Authorization = `Bearer ${authData?.token}`;

    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url: any, params: any | null) => {
    let response: any;

    let paramKeys: any = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url: any, data: any) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url: any, data: any) => {
    return axios.patch(url, data);
  };

  put = (url: any, data: any) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url: any, config: any) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
