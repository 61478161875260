import React from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import ReactInputMask from "react-input-mask";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import SearchResults from "Components/admin/searchEnergyVoucher/SearchResults";

import "./SearchEnergyVoucher.styles.scss";

const SearchEnergyVoucherAdmin = () => {
  document.title = "Pesquisar Vale de Energia | S Company";

  const validation: any = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values: any) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Pesquisar Vale Energia" />

          <Card className="search-box-energy-voucher">
            <CardBody>
              <h3 className="search-box_title">Pesquisar</h3>

              <Form>
                {/* <Row>
                  <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-energy-voucher-name"
                        md="2"
                        className="col-form-label"
                      >
                        Nome
                      </Label>
                      <Col>
                        <Input
                          type="text"
                          id="search-box-energy-voucher-name"
                          placeholder="Digite o nome"
                          name="name"
                          value={validation.values.name}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-energy-voucher-email"
                        md="2"
                        className="col-form-label"
                      >
                        E-mail
                      </Label>
                      <Col>
                        <Input
                          type="text"
                          id="search-box-energy-voucher-email"
                          placeholder="example@example.com"
                          name="email"
                          value={validation.values.email}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                  </Col>
                </Row> */}

                <Row>
                  <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-energy-voucher-document"
                        md="2"
                        className="col-form-label"
                      >
                        CPF / CNPJ
                      </Label>
                      <Col>
                        <ReactInputMask
                          id="search-box-energy-voucher-document"
                          mask="99.999.999/9999-99"
                          maskChar="_"
                          placeholder="000.000.000-00"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          style={{
                            width: "100%",
                            color: "#495057",
                            backgroundColor: "#fff",
                            border: "1px solid #b1bbc4",
                            borderRadius: "0.25rem",
                            padding: "0.47rem 0.75rem",
                          }}
                        />
                        {/* <Input
                          type="text"
                          id="search-box-energy-voucher-document"
                          placeholder="000.000.000-00"
                          name="document"
                          value={validation.values.document}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.document &&
                            validation.errors.document
                              ? true
                              : false
                          }
                        /> */}
                        {validation.touched.document &&
                        validation.errors.document ? (
                          <FormFeedback type="invalid">
                            {validation.errors.document}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                  </Col>
                  {/* <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-energy-voucher-phone"
                        md="2"
                        className="col-form-label"
                      >
                        Telefone
                      </Label>
                      <Col>
                        <Input
                          type="text"
                          id="search-box-energy-voucher-phone"
                          placeholder="(99) 99999-9999"
                          name="phone"
                          value={validation.values.phone}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                  </Col> */}
                </Row>

                {/* <Row>
                  <Col>
                    <FormGroup row className="align-items-center">
                      <Label md={2}>
                        Data de
                        <br /> Início
                      </Label>
                      <Col>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block datepicker-search-box-energy-voucher"
                            placeholder="Selecione a Data"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "d-m-Y",
                              locale: Portuguese,
                            }}
                            translate="yes"
                          />
                        </InputGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row className="align-items-center">
                      <Label md={2}>
                        Data de
                        <br /> Término
                      </Label>
                      <Col>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block datepicker-search-box-energy-voucher"
                            placeholder="Selecione a Data"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "d-m-Y",
                              locale: Portuguese,
                            }}
                            translate="yes"
                          />
                        </InputGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row> */}

                <Row className="mt-4">
                  <Col className="d-flex gap-2 justify-content-end">
                    <button className="btn btn-clean_search-box-energy-voucher">
                      Limpar
                    </button>
                    <button
                      className="btn btn-search_search-box-energy-voucher"
                      onClick={validation.handleSubmit}
                    >
                      Pesquisar
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <SearchResults />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchEnergyVoucherAdmin;
