import { postVoucherPartner, getVoucherPartner } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchSuccess,
  postSuccess
} from "./reducer";

export const fetchVouchers = (partnerId: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());
    const response = await getVoucherPartner({ partnerId });

    console.log('aqui', response)
    dispatch(fetchSuccess(response));
  } catch (error) {
    console.log("erro ao listar creditos", error);
    dispatch(apiError(error));
  }
};

export const postVoucher = (data: any, history: any) => async (dispatch: any) => {
  try {
    dispatch(isFetching());
    console.log('testaaa', data)
    const response = await postVoucherPartner(data);

    dispatch(postSuccess(response));
    history('/partnerCompany/procurar-vale-de-energia')
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

