import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
  totalCounters: {
    credits: 0,
    users: 0,
    partners: 0,
    totalBillsPaid: 0
  },
  simpleHistory: [
    {
      date: "",
      description: ""
    }
  ],
  charts: {
    billsPayedsByMonth: {
      vouchers: [
        {
          createdAt: "",
          count: ""
        }
      ],
      billsPayeds: [
        {
          createdAt: "",
          count: ""
        }
      ]
    },
    newUsersByMonth: {
      users: [
        {
          createdAt: "",
          count: ""
        }
      ],
      partners: [
        {
          createdAt: "",
          count: ""
        }
      ]
    },
    vouchersByPartners: [
      {
        partnerName: "",
        count: ""
      }
    ]
  },
  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    fetchSuccess(state, action) {
      // User data
      state.totalCounters = action.payload.totalCounters;
      state.simpleHistory = action.payload.simpleHistory;
      state.charts = action.payload.charts;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  apiError,
  isFetching,
  fetchSuccess,
  clearState,
} = adminDashboardSlice.actions;

export const adminDashboardSelector = (state: RootState) => state.AdminDashboard;

export default adminDashboardSlice.reducer;