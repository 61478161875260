import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import "./cardStatus.styles.scss";

const CardStatusAdmin = ({ data }: any) => {
  const [hideBalance, setHideBalance] = useState(false);

  useEffect(() => {console.log('data', data)}, [data])
  return (
    <React.Fragment>
      {/* <Card className="total-balance-card_partner-company">
        <CardBody>
          <CardTitle className="mb-3">Status do sistema</CardTitle>

          <p>
            <CheckCircleOutlineIcon
              style={{ marginRight: "5px", color: "#34C38F" }}
            />
            <span className="fw-bold">Emissão de contas</span> expires today{" "}
            <span className="text-success">Ativada</span>
          </p>
          <p>
            <DangerousOutlinedIcon
              style={{ marginRight: "5px", color: "#F46A6A" }}
            />
            <span className="fw-bold">Pagamentos</span> expires today{" "}
            <span className="text-danger">Desativada</span>
          </p>
        </CardBody>
      </Card> */}
      <div className="w-100 d-flex flex-column gap-1">
        <Link
          to="/admin/recarregar-saldo"
          className="btn-card-status"
        >
          Emitir Crédito
          <i className="mdi mdi-text-box-plus"></i>
        </Link>
        <Link
          to="/admin/criar-usuario"
          className="btn-card-status btn-new-user_card_status d-none d-lg-flex"
        >
          Novo Parceiro
          <i>
            <PersonAddAlt1Icon />
          </i>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default CardStatusAdmin;
