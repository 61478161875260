import { getParnterDashboard, getAdminDashboard } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchSuccess,

} from "./reducer";

export const fetchData = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getAdminDashboard(null);

    dispatch(fetchSuccess(response));
  } catch (error) {
    console.log("erro ao fazer buscar dados", error);
    dispatch(apiError(error));
  }
};


export const fetchPartners = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    // const response = await getParntersAdmin(null);

    // dispatch(fetchPatnersSuccess(response));
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

