import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardTitle } from "reactstrap";

// import UserEditModal from "./UserEditModal";
import TableContainer from "Components/Common/TableContainer";

import {
  OrderId,
  BillingName,
  BillingSocialName,
  Date,
  Total,
  PaymentStatus,
} from "./LatestTransactionCol";

import "./transactionsTable.styles.scss";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { partnerDashboardSelector } from "slices/admin/credits/reducer";

import { fetchCredits } from "slices/admin/credits/thunk";
import UserEditModal from "Components/partnerCompany/searchUser/SearchResults/UserEditModal";
import Receipt from "./Receipt";

const SearchResultsAdmin = () => {
  const [modal1, setModal1] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<any>("");
  const [modalDetails, setModalDetails] = useState<any>({});

  const dispatch: any = useDispatch();
  const { credits, loading } = useAppSelector(partnerDashboardSelector);

  const toggleViewModal = useCallback(
    (value: any) => {
      setModal1(!modal1);
      setEditDetails(value);
    },
    [modal1]
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: (cellProps: any) => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Razão Social",
        accessor: "socialName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingSocialName {...cellProps} />;
        },
      },
      {
        Header: "Nome Fantasia",
        accessor: "fantasyName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Criado Em",
        accessor: "createdAt",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Valor",
        accessor: "amount",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <PaymentStatus value="Ativo" {...cellProps} />;
        },
      },
      {
        Header: "Ver detalhes",
        disableFilters: true,
        accessor: "view",
        Filter: false,
        Cell: (cellProps: any) => {
          console.log('test cell pros', cellProps)
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                setModalDetails(cellProps.cell.row.original)
                toggleViewModal(cellProps.cell.row.original)
              }}
            >
              {" "}
              Ver Detalhes{" "}
            </Button>
          );
        },
      },
    ],
    [toggleViewModal]
  );

  useEffect(() => {
    dispatch(fetchCredits(null));
  }, []);

  return (
    <React.Fragment>
      <Receipt isOpen={modal1} toggle={toggleViewModal} creditData={modalDetails} />
      <Card className="transactions-table">
        <CardBody>
          <CardTitle tag="h4" className="mb-4">
            Resultados da pesquisa
          </CardTitle>
          <TableContainer
            columns={columns}
            data={credits}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SearchResultsAdmin;
