import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "react-select";

import EnergyVoucher from "Components/finalClient/home/energyVoucher";

import {
  Row,
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";

import "./IndividualIssuance.styles.scss";

const customStylesNameSelect = {
  container: (provided) => ({
    ...provided,
    position: "relative",

    height: "2rem",
  }),
  control: (provided) => ({
    ...provided,
    height: "100%",
    paddingLeft: "1rem",
  }),

  menu: (provided) => ({
    ...provided,
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#333",
    padding: 8,
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  icon: {
    marginRight: 12,
  },
};

const customStylesMonthSelect = {
  container: (provided) => ({
    ...provided,
    position: "relative",

    width: "6.25rem",
    height: "2rem",
  }),
  control: (provided) => ({
    ...provided,
    height: "100%",
  }),

  menu: (provided) => ({
    ...provided,
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#333",
    padding: 8,
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  icon: {
    marginRight: 12,
  },
};

const options = [
  { value: "123", label: "Igor Carvalho" },
  { value: "124", label: "Ycaro Guth" },
];

const numbers = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

const IndividualIssuance = () => {
  const validation: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      country: "",
      states: "",
      order: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, digite seu nome."),
      email: Yup.string().email().required("Por favor, digite seu email."),
      phone: Yup.string().required("Por favor, digite seu telefone."),
    }),
    onSubmit: (values: any) => {
      // console.log(values)
    },
  });

  return (
    <TabPane tabId="1" className="issueEnergyVoucherTab">
      <h2 className="title-tab_issueEnergyVoucher">1. Dados do Beneficiário</h2>

      <div>
        <Form onSubmit={validation.handleSubmit}>
          <FormGroup className="mb-4" row>
            <Label
              htmlFor="individual-issuance-name"
              md="2"
              className="col-form-label"
            >
              Nome
            </Label>
            <Col md={10}>
              <Select
                options={options}
                styles={customStylesNameSelect}
                components={{
                  SingleValue: ({ data, ...props }) => {
                    return <div {...props}>{data?.label}</div>;
                  },
                }}
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
          <Row className="d-flex flex-row">
            <Col>
              <FormGroup row>
                <Label
                  htmlFor="individual-issuance-email-address"
                  md="4"
                  className="col-form-label"
                >
                  CPF/CNPJ
                </Label>
                <Col>
                  <Input
                    type="email"
                    id="individual-issuance-email-address"
                    placeholder="Digite seu email"
                    name="email"
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup row>
                <Label
                  htmlFor="individual-issuance-email-address"
                  md="3"
                  className="col-form-label"
                >
                  Matricula
                </Label>
                <Col>
                  <Input
                    type="email"
                    id="individual-issuance-email-address"
                    placeholder="Digite a matricula"
                    name="email"
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>

      <hr className="mt-4 mb-4 divider" />

      <div>
        <h2 className="title-tab_issueEnergyVoucher mt-3">2. Vale Energia</h2>

        <FormGroup className="form-check form-switch mb-3 form-switch_issueEnergyVoucher">
          <Label
            htmlFor="individual-issuance-monthly-recurrence"
            className="col-form-label"
          >
            Recorrência Mensal
          </Label>
          <input
            type="checkbox"
            className="form-check-input switch_issueEnergyVoucher"
            id="customSwitchsizemd"
          />
        </FormGroup>

        <FormGroup className="mb-4" row>
          <Label
            htmlFor="individual-issuance-name"
            md="1"
            class
            Name="col-form-label"
          >
            Meses
          </Label>
          <Col>
            <Select
              options={numbers}
              styles={customStylesMonthSelect}
              placeholder="QTD"
              components={{
                SingleValue: ({ data, ...props }) => {
                  return <div {...props}>{data?.label}</div>;
                },
              }}
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup className="recharge-value">
          <Label md="1">
            Valor do <br /> Vale Energia
          </Label>
          <div className="d-flex align-items-center px-3">
            <div className="recharge-value-symbol">R$</div>
            <Input type="text" />
          </div>
        </FormGroup>
      </div>

      <hr className="mt-4 mb-4 divider" />

      <div className="card-energyVoucher_issueEnergyVoucher">
        <h2 className="title-tab_issueEnergyVoucher mt-3">
          3. Pré-visualização do Vale Energia
        </h2>

        <EnergyVoucher />
      </div>

      <Row className="mt-4">
        <button className="btn btn-issueIndividualEnergyVoucherAdmin">
          Emitir Vale Energia Individual
        </button>
      </Row>
    </TabPane>
  );
};

export default IndividualIssuance;
