import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardTitle, Spinner } from "reactstrap";

import UserEditModal from "./UserEditModal";
import TableContainer from "Components/Common/TableContainer";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
} from "./LatestTransactionCol";

import "./transactionsTable.styles.scss";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { partnerDashboardSelector } from "slices/admin/credits/reducer";

import { fetchPartners } from "slices/admin/credits/thunk";

const latestTransactionData = [
  {
    orderId: "#SK2545",
    billingName: "Jacob Hunter",
    orderdate: "04 Oct, 2019",
    total: "$392",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2544",
    billingName: "Ronald Taylor",
    orderdate: "04 Oct, 2019",
    total: "$404",
    paymentStatus: "Refund",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "05 Oct, 2019",
    total: "$412",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "06 Oct, 2019",
    total: "$384",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "07 Oct, 2019",
    total: "$380",
    paymentStatus: "Chargeback",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
];

const SearchResultsAdmin = () => {
  const [modal1, setModal1] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<any>("");
  
  const dispatch: any = useDispatch();
  const { partners, loading } = useAppSelector(partnerDashboardSelector);

  const toggleViewModal = useCallback(
    (value: any) => {
      setModal1(!modal1);
      setEditDetails(value);
    },
    [modal1]
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: (cellProps: any) => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Razão Social",
        accessor: "socialName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Nome Fantasia",
        accessor: "fantasyName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Criado Em",
        accessor: "createdAt",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "pstatus",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <PaymentStatus value="Ativo"  />;
        },
      },
      {
        Header: "Ver detalhes",
        disableFilters: true,
        accessor: "view",
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              // onClick={() => toggleViewModal(cellProps.cell.row.original)}
            >
              {" "}
              Ver Detalhes{" "}
            </Button>
          );
        },
      },
    ],
    [toggleViewModal]
  );

  useEffect(() => {
    dispatch(fetchPartners(null))
  }, [])

  return (
    <React.Fragment>
      <UserEditModal
        isOpen={modal1}
        toggle={toggleViewModal}
        editDetails={editDetails}
      />
      <Card className="transactions-table">
        <CardBody>
          <CardTitle tag="h4" className="mb-4">
            Resultados da pesquisa { loading && <Spinner style={{ marginLeft: 5 }} size={'sm'} />}
          </CardTitle>
          <TableContainer
            columns={columns}
            data={partners}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SearchResultsAdmin;
