import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Row,
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Modal,
  Spinner,
} from "reactstrap";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

import "./CreateIndividualUser.styles.scss";
import Dropzone from "react-dropzone";

import cep from "cep-promise";
import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { partnerPartnerSelector, resetErrorFlag } from "slices/admin/partner/reducer";

import { createPartner } from "slices/admin/partner/thunk";
import { Button } from "@mui/material";
import withRouter from "Components/Common/withRouter";

const CreateIndividualUser = (props: any) => {
  const dispatch: any = useDispatch();

  const { error, errorMsg, loading } = useAppSelector(partnerPartnerSelector);

  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModalError() {
    setModalError(!modalError);
    setErrorMessage("");
    removeBodyCss();
  }
  const validationPartnerCompany: any = useFormik({
    initialValues: {
      socialName: '',
      fantasyName: '',
      document: '',
      phone: '',
      email: '',
      addressStreet: '',
      addressStreetNumer: '',
      addressZipCode: '',
      addressNeighborhood: '',
      addressCity: '',
      addressState: '',
      addressComplement: '',
    },
    validationSchema: Yup.object({
      socialName: Yup.string().required("Por favor, digite a razão social."),
      document: Yup.string().required("Por favor, digite o CNPJ."),
      fantasyName: Yup.string().required("Por favor, digite o nome fantasia."),
      email: Yup.string().email().required("Por favor, digite seu email."),
      phone: Yup.string().required("Por favor, digite o telefone."),
      addressZipCode: Yup.string().required("Por favor, digite o CEP."),
      addressStreet: Yup.string().required("Por favor, digite o endereço."),
      addressStreetNumer: Yup.string().required("Por favor, digite o número do endereço."),
      addressCity: Yup.string().required("Por favor, digite a cidade."),
      addressComplement: Yup.string(),
    }),
    onSubmit: (values: any) => {
      console.log('on submit', values)
      dispatch(createPartner(values, props.router.navigate));
    },
  });

  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const handleCpfCnpjChange = (event) => {
    const { value } = event.target;
    const maskedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    const formattedValue = maskedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1.$2.$3/$4-"); // CNPJ

      validationPartnerCompany.setFieldValue("document", formattedValue);
  };

  const handleTelefoneChange = (event) => {
    const { value } = event.target;
    const maskedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    const formattedValue =
      maskedValue.length <= 10
        ? maskedValue.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") // (DD) NNNN-NNNN
        : maskedValue.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"); // (DD) NNNNN-NNNN

    validationPartnerCompany.setFieldValue("phone", formattedValue);
  };
  
  const handleCepChange = (event) => {
    const { value } = event.target;
    const maskedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    const formattedValue = maskedValue.replace(/(\d{5})(\d{3})/, "$1-$2");

    validationPartnerCompany.setFieldValue("addressZipCode", formattedValue);

    if (formattedValue.length === 9) {
      cep(formattedValue).then((addr) => {
        console.log("test", addr);
        validationPartnerCompany.setFieldValue("addressStreet", addr.street);
        validationPartnerCompany.setFieldValue("addressCity", addr.city);
        validationPartnerCompany.setFieldValue("addressState", addr.state);
        validationPartnerCompany.setFieldValue("addressNeighborhood", addr.neighborhood);
      }).catch(() => console.log('Erro ao buscar CEP para preenchimento automatico de endereço'));
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);

      setModalError(true);

      setTimeout(() => {
        dispatch(resetErrorFlag());
      }, 1000);
    }
  }, [dispatch, error]);

  return (
    <TabPane tabId="1" className="userManagementTab">
      <h2 className="title-tab_userManagement">1. Dados do Parceiro</h2>
      <div>
        <Form
          onSubmit={validationPartnerCompany.handleSubmit}
          // validate={validationPartnerCompany}
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   validationPartnerCompany.handleSubmit();
          //   // return false;
          // }}
        >
            <Row className="d-flex flex-row">
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-name"
                    className="col-form-label"
                  >
                    Razão Social
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-name"
                      placeholder="Digite a razão social"
                      name="socialName"
                      value={validationPartnerCompany.values.socialName}
                      onChange={validationPartnerCompany.handleChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.socialName &&
                        validationPartnerCompany.errors.socialName
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.socialName &&
                    validationPartnerCompany.errors.socialName ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.socialName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-name"
                    className="col-form-label"
                  >
                    Nome Fantasia
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-FantasyName"
                      placeholder="Digite o Nome Fantasia"
                      name="fantasyName"
                      value={validationPartnerCompany.values.fantasyName}
                      onChange={validationPartnerCompany.handleChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.fantasyName &&
                        validationPartnerCompany.errors.fantasyName
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.fantasyName &&
                    validationPartnerCompany.errors.fantasyName ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.fantasyName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex flex-row">
            <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-document"
                    className="col-form-label"
                  >
                    CNPJ
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-document"
                      placeholder="Digite o CNPJ"
                      name="document"
                      maxLength={18}
                      value={validationPartnerCompany.values.document}
                      onChange={handleCpfCnpjChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.document &&
                        validationPartnerCompany.errors.document
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.document &&
                    validationPartnerCompany.errors.document ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.document}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-phone"
                    className="col-form-label"
                  >
                    Telefone
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-phone"
                      placeholder="Digite o telefone"
                      name="phone"
                      value={validationPartnerCompany.values.phone}
                      onChange={handleTelefoneChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.phone &&
                        validationPartnerCompany.errors.phone
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.phone &&
                    validationPartnerCompany.errors.phone ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex flex-row">
            <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-email"
                    className="col-form-label"
                  >
                    Email
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-email"
                      placeholder="Digite o email"
                      name="email"
                      value={validationPartnerCompany.values.email}
                      onChange={validationPartnerCompany.handleChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.email &&
                        validationPartnerCompany.errors.email
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.email &&
                    validationPartnerCompany.errors.email ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.email}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-zip"
                    className="col-form-label"
                  >
                    CEP
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-zip"
                      placeholder="Digite o CEP"
                      name="addressZipCode"
                      maxLength={9}
                      onChange={handleCepChange}
                      value={validationPartnerCompany.values.addressZipCode}
                      // onChange={validationPartnerCompany.handleChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.addressZipCode &&
                        validationPartnerCompany.errors.addressZipCode
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.addressZipCode &&
                    validationPartnerCompany.errors.addressZipCode ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.addressZipCode}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex flex-row">
            <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-city"
                    className="col-form-label"
                  >
                    Cidade
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-city"
                      placeholder="Digite a cidade"
                      name="addressCity"
                      maxLength={50}
                      value={validationPartnerCompany.values.addressCity}
                      onChange={validationPartnerCompany.handleChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.addressCity &&
                        validationPartnerCompany.errors.addressCity
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.addressCity &&
                    validationPartnerCompany.errors.addressCity ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.addressCity}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-address"
                    className="col-form-label"
                  >
                    Rua
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-address"
                      placeholder="Digite o endereço"
                      name="addressStreet"
                      maxLength={100}
                      value={validationPartnerCompany.values.addressStreet}
                      onChange={validationPartnerCompany.handleChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.addressStreet &&
                        validationPartnerCompany.errors.addressStreet
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.addressStreet &&
                    validationPartnerCompany.errors.addressStreet ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.addressStreet}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
              
            </Row>
            <Row className="d-flex flex-row">
            <Col>
                <FormGroup>
                  <Label
                    htmlFor="create-partnerCompany-individual-addressNumber"
                    className="col-form-label"
                  >
                    Número
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      id="create-partnerCompany-individual-addressNumber"
                      placeholder="Digite o número do endereço"
                      name="addressStreetNumer"
                      maxLength={10}
                      value={validationPartnerCompany.values.addressStreetNumer}
                      onChange={validationPartnerCompany.handleChange}
                      onBlur={validationPartnerCompany.handleBlur}
                      invalid={
                        validationPartnerCompany.touched.addressStreetNumer &&
                        validationPartnerCompany.errors.addressStreetNumer
                          ? true
                          : false
                      }
                    />
                    {validationPartnerCompany.touched.addressStreetNumer &&
                    validationPartnerCompany.errors.addressStreetNumer ? (
                      <FormFeedback type="invalid">
                        {validationPartnerCompany.errors.addressStreetNumer}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label
                    htmlFor="create-partnerCompany-individual-complement"
                    className="col-form-label"
                  >
                    Complemento
                  </Label>
                  <Input
                    type="text"
                    id="create-partnerCompany-individual-complement"
                    placeholder="Digite o complemento"
                    name="addressComplement"
                    maxLength={100}
                    value={validationPartnerCompany.values.addressComplement}
                    onChange={validationPartnerCompany.handleChange}
                    onBlur={validationPartnerCompany.handleBlur}
                    invalid={
                      validationPartnerCompany.touched.addressComplement &&
                      validationPartnerCompany.errors.addressComplement
                        ? true
                        : false
                    }
                  />
                  {validationPartnerCompany.touched.addressComplement &&
                  validationPartnerCompany.errors.addressComplement ? (
                    <FormFeedback type="invalid">
                      {validationPartnerCompany.errors.addressComplement}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            {/* <Label>Logo da Empresa (Opcional) (.JPG, .PNG, máx 2MB)</Label>
            <Dropzone
              onDrop={(acceptedFiles: any) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }: any) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Arraste aqui ou clique para escolher.</h4>
                  </div>
                </div>
              )}
            </Dropzone> */}
            <Row className="mt-4">
              <button
                className="btn btn-CreateIndividualUser" 
                type="submit"
                disabled={loading}
              >
                {loading ? <Spinner /> : 'Criar Parceiro'}
              </button>
            </Row>
          </Form>
      </div>
      <Modal
          className="modal-auth-page"
          size="sm"
          isOpen={modalError}
          toggle={() => {
            toggleModalError();
          }}
          centered
        >
          <div className="modal-body">
            <DangerousOutlinedIcon />

            <p>{errorMessage}</p>

            <Button
              onClick={() => {
                toggleModalError();
                // props.router.navigate('/');
              }}
            >
              Entendi
            </Button>
          </div>
        </Modal>
    </TabPane>
  );
};

export default withRouter(CreateIndividualUser);
