import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Card,
  CardBody,
} from "reactstrap";

import classnames from "classnames";

import Breadcrumb from "Components/Common/Breadcrumb";

import PersonalInformation from "Components/partnerCompany/perfil/personalInformation";
import Security from "Components/partnerCompany/perfil/security";
import CodeFA from "Components/partnerCompany/perfil/2FA";
import PartnerCompanyFinancial from "Components/partnerCompany/perfil/financial";

import "./perfil.styles.scss";

const PerfilPartnerCompany = () => {
  //meta title
  document.title = "Perfil | S Company";

  const [activeTab, setactiveTab] = useState<any>("1");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Início" breadcrumbItem="Perfil" />

          <div className="perfil-tabs-partner">
            <Row className="align-items-center mb-5 info-user">
              <Col>
                <p className="message">Bem-vindo ao S Company</p>
                <h2 className="name-user">Grupo Arnone</h2>
                <p className="email-user">contato@grupoarnone.com</p>
              </Col>
              <Col className="d-md-flex d-none align-items-center justify-content-end gap-5 details-user">
                <div className="text-center">
                  <p className="title-detail">Telefone</p>
                  <p className="describe-detail">(11) 4480-1510</p>
                </div>
                <div className="text-center">
                  <p className="title-detail">CPF / CNPJ</p>
                  <p className="describe-detail">52.106.469/0001-03</p>
                </div>
                {/* <div className="text-center">
                  <p className="title-detail">Matrícula</p>
                  <p className="describe-detail">03141563</p>
                </div> */}
              </Col>
            </Row>

            <Row>
              <Col lg={3} sm={3}>
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1");
                      }}
                    >
                      <i className="mdi mdi-account-outline d-block check-nav-icon" />
                      <p className="fw-bold">Informações Gerais</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2");
                      }}
                    >
                      <i className="mdi mdi-shield-check d-block check-nav-icon" />
                      <p className="fw-bold">Segurança</p>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3");
                      }}
                    >
                      <i className="mdi mdi-qrcode d-block check-nav-icon" />
                      <p className="fw-bold">2FA</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        setactiveTab("4");
                      }}
                    >
                      <i className="mdi mdi-scale-balance d-block check-nav-icon" />
                      <p className="fw-bold">Financeiro</p>
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
              <Col lg={9} sm={9}>
                <Card className="w-100">
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <PersonalInformation />

                      <Security />

                      <CodeFA />

                      <PartnerCompanyFinancial />
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PerfilPartnerCompany;
