import { getParnterDashboard } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchSuccess,
  clearState,
} from "./reducer";

export const fetchData = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());
    dispatch(clearState(null));
    const response = await getParnterDashboard(null);
    dispatch(fetchSuccess(response));
  } catch (error) {
    console.log(error);
    dispatch(clearState(null));
    dispatch(apiError(error));
  }
};

