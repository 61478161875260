import React from "react";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import SearchResultsAdmin from "Components/admin/searchUser/SearchResults";

import "./SearchUser.styles.scss";

const SearchUserAdmin = () => {
  document.title = "Pesquisar Parceiro | S Company";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Pesquisar Parceiro" />

          <Card className="search-box-user">
            <CardBody>
              <h3 className="search-box_title">Pesquisar</h3>

              <Form>
                <Row>
                  <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-user-name"
                        md="2"
                        className="col-form-label"
                      >
                        Nome
                      </Label>
                      <Col>
                        <Input
                          type="text"
                          id="search-box-user-name"
                          placeholder="Digite o nome"
                          name="name"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-user-id"
                        md="2"
                        className="col-form-label"
                      >
                        ID
                      </Label>
                      <Col>
                        <Input
                          type="text"
                          id="search-box-user-id"
                          placeholder="000000000"
                          name="name"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-user-document"
                        md="2"
                        className="col-form-label"
                      >
                        CNPJ
                      </Label>
                      <Col>
                        <Input
                          type="text"
                          id="search-box-user-document"
                          placeholder="000.000.000-00"
                          name="document"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup row>
                      <Label
                        htmlFor="search-box-user-email"
                        md="2"
                        className="col-form-label"
                      >
                        E-mail
                      </Label>
                      <Col>
                        <Input
                          type="text"
                          id="search-box-user-email"
                          placeholder="example@example.com"
                          name="email"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col className="d-flex gap-2 justify-content-end">
                    <button className="btn btn-clean_search-box-user">
                      Limpar
                    </button>
                    <button className="btn btn-search_search-box-user">
                      Pesquisar
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <SearchResultsAdmin />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchUserAdmin;
