import { getFirebaseBackend } from "helpers/firebase_helper";
import {
  postJwtLogin,
  postUpdateUser,
  getJwtMe,
  postJwtForgetPwd,
  postJwtResetPwd,
} from "helpers/fakebackend_helper";
import {
  loginSuccess,
  apiError,
  logoutUserSuccess,
  resetLoginFlag,
  updateUserSuccess,
  loading,
  forgotPassEmailSent,
  resetPassEmailSuccess,
} from "./reducer";

import { setAuthorization } from "helpers/api_helper";

export const loginuser = (user: any, history: any) => async (dispatch: any) => {
  try {
    dispatch(loading());

    const response = await postJwtLogin({
      email: user.email,
      password: user.password,
      reCaptchaToken: user.reCaptchaToken,
    });

    setAuthorization(response.token);

    dispatch(loginSuccess(response));

    dispatch(loading());

    history("/");
  } catch (error) {
    dispatch(loading());

    console.log("erro ao fazer login", error);
    dispatch(apiError(error));
  } finally {
    dispatch(loading());
  }
};

export const updateUser =
  (user: any, history: any | null) => async (dispatch: any) => {
    try {
      console.log("test udpate", user);
      dispatch(loading());

      await postUpdateUser({
        reCaptchaToken: user.reCaptchaToken,
        userId: user.userId,
        status: user.status,
        name: user.name,
        email: user.email,
        document: user.document,
        phone: user.phone,
        cep: user.cep,
        city: user.city,
        address: user.address,
        addressNumber: user.addressNumber,
        complement: user.complement,
        state: user.state,
        password: user.password,
        confirmPassword: user.confirmPassword,
      });

      dispatch(updateUserSuccess(user));

      dispatch(loading());

      if (history != null) history("/");
    } catch (error) {
      dispatch(loading());

      console.log("erro ao atualizar cadastro", error);
      dispatch(apiError(error));
    } finally {
      dispatch(loading());
    }
  };

  export const updateUserRegister =
  (user: any, history: any | null) => async (dispatch: any) => {
    try {
      console.log("test udpate", user);
      dispatch(loading());

      await postUpdateUser(user);

      dispatch(updateUserSuccess(user));

      dispatch(loading());

      if (history != null) history("/");
    } catch (error) {
      dispatch(loading());

      console.log("erro ao atualizar cadastro", error);
      dispatch(apiError(error));
    } finally {
      dispatch(loading());
    }
  };

export const logoutUser = (history: any) => async (dispatch: any) => {
  try {
    //deslogar no backend?
    dispatch(logoutUserSuccess(true));
    history("/");
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginMsgFlag = () => {
  try {
    const response = resetLoginFlag();
    return response;
  } catch (error) {
    return error;
  }
};

export const socialLogin =
  (type: any, history: any) => async (dispatch: any) => {
    try {
      let response: any;

      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const fireBaseBackend = getFirebaseBackend();
        response = fireBaseBackend.socialLoginUser(type);
      }

      const socialdata = await response;
      if (socialdata) {
        sessionStorage.setItem("authUser", JSON.stringify(socialdata));
        dispatch(loginSuccess(socialdata));
        history("/dashboard");
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const getMe = (authToken?: string) => async (dispatch: any) => {
  try {
    dispatch(loading());

    setAuthorization(authToken);
    const response = await getJwtMe(null);

    console.log("retorno /me: ", response);
    dispatch(loginSuccess({ user: response, token: authToken }));
  } catch (error) {
    dispatch(loading());

    console.log("erro ao fazer login", error);
    dispatch(apiError(error));
  }
};

export const forgotPassword = (email?: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response = await postJwtForgetPwd(email);

    console.log("retorno /esqueci-senha: ", response);

    dispatch(forgotPassEmailSent(response));
  } catch (error) {
    dispatch(loading());

    console.log("erro ao solicitar recuperar de senha", error);
    dispatch(apiError(error));
  }
};

export const resetPassword = (data?: any) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response = await postJwtResetPwd(data);

    console.log("retorno /alterar-senha: ", response);

    dispatch(resetPassEmailSuccess(response));
  } catch (error) {
    dispatch(loading());

    console.log("erro ao alterar de senha", error);
    dispatch(apiError(error));
  }
};
