import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import moment from 'moment';

const CheckBox = (cell:any) => {
    return cell.value ? cell.value : '';
};

const OrderId = (cell:any) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const BillingName = (cell:any) => {
    return cell.value ? cell.value : '';
};

const Date = (cell:any) => {
    moment.locale('pt-br'); 
  return cell.value ? moment(cell.value).locale('pt-br').format('DD/MM/YYYY') : "";
};

const Total = (cell:any) => {
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(cell?.value || '0'));
};

const PaymentStatus = (cell:any) => {
    return (
        <Badge
          className={"font-size-11 badge-soft-" + 
          (cell.value === "CONFIRMED" ? "success" : "warning" && cell.value === "PAYMENT_PENDING" ? "warning" : "danger")}          
        >
          {/* {cell.value} */}
          {(cell.value === "CONFIRMED" ? "Pago" : "Em Processamento" && cell.value === "PAYMENT_PENDING" ? "Em Processamento" : "danger")}
        </Badge>
    )
};
const PaymentMethod = (cell:any) => {
    return (
        <span>
        <i
        className={
          (cell.value === "PIX" ? "fab fas fa-money-bill-alt me-1" : "" || 
          cell.value === "Vale" ? "fab fas fa-money-bill-alt me-1" : ""
          )}
          />
            {cell.value}
        </span>
    )
};
export {
    CheckBox,
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod
};