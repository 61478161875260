//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_LOGIN = "/api/v1/auth/email/login";
export const GET_ME = "/api/v1/auth/me";
export const POST_UPDATE_USER = "/api/v1/users/";
export const POST_PASSWORD_FORGET = "/api/v1/auth/forgot/password";
export const POST_PASSWORD_RESET = "/api/v1/auth/reset/password";

export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";



//CLIENT DASHBOARD
export const GET_CLIENT_DASHBOARD = "/api/v1/dashboards/me";

//PARTNER DASHBOARD
export const GET_PARTNER_DASHBOARD = "/api/v1/dashboards/partner/me";


//ADMIN PARTNERS
export const GET_PARTNERS_ADMIN = "/api/v1/partner";
//ADMIN PARTNERS
export const POST_PARTNERS_ADMIN = "/api/v1/partner";

//CLIENT BILL
export const GET_BILL_ADMIN = "/api/v1/bills";


//ADMIN DASHBOARD
export const GET_ADMIN_DASHBOARD = "/api/dashboards/admin";

//ADMIN CREDITS
export const GET_CREDITS_ADMIN = "/api/v1/credits";

//ADMIN CREDITS 
export const POST_CREDITS_ADMIN = "/api/v1/credits";

//ADMIN CREDITS 
export const GET_PAYMENT_BACKOFFICE_ADMIN = "/api/v1/credits";

//ADMIN CREDITS CONFIRM
export const UPDATE_PAYMENT_BACKOFFICE_ADMIN = "/api/v1/credits";

//ADMIN CREDITS CONFIRM
export const UPDATE_BILL_BACKOFFICE_ADMIN = "/api/v1/bills";

//PARTNER VOUCHERS
export const GET_VOUCHER_PARTNER = "/api/v1/vouchers";

//PARTNER VOUCHERS 
export const POST_VOUCHER_PARTNER = "/api/v1/vouchers";

//CLIENT BILL
export const GET_BILL_CLIENT = "/api/v1/bills/me";
export const GET_BILL_CLIENT_INFO = "/api/v1/bills/info";