import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
  Modal,
  Button,
} from "reactstrap";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { forgotPassword, resetLoginMsgFlag } from "slices/auth/thunk";

import ImageAuth from "../../assets/images/auth-bg.png";
import Logo from "../../assets/images/logo/scompany-logo-black.png";

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Resetar Senha | S Company";

  const dispatch = useDispatch<any>();

  const { error, loading, emailResetSent } = useAppSelector(authSelector);

  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModalError() {
    setModalError(!modalError);
    setErrorMessage("");
    removeBodyCss();
  }

  // Form validation
  const validation: any = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("Por favor, digite o seu email.")
    }),
    onSubmit: (values: any) => {
      dispatch(forgotPassword(values));
    },
  });

  useEffect(() => {
    if (error) {
      // setErrorMessage(error);
      setErrorMessage("Email não encontrado");
      setModalError(true);

      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 1000);
    }
  }, [dispatch, error]);
  
  useEffect(() => {
    dispatch(resetLoginMsgFlag());
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages w-100">
        <Row className="w-100">
          <Col className="container-left-auth d-none d-lg-flex">
            <img src={ImageAuth} alt="Duas pessoas dando risada" />
          </Col>
          <Col xl={3} className="container-right-auth">
            <div className="w-100 h-100">
              <Row>
                <div className="text-primary p-4 d-flex justify-content-center logo-header">
                  <img src={Logo} alt="" />
                </div>
              </Row>

              <Row className="p-3 form-auth">
                { 
                  !emailResetSent && <div>
                    <div>
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >                      
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>

                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Digite o seu e-mail"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block btn-auth"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <Spinner /> : "Resetar Senha"}
                          </button>
                          <Link className="btn button-back-to-login" to="/">
                            {/* <i className="bx bx-chevron-left" /> */}
                            Voltar para Login
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </div>
                }

                {
                  emailResetSent && <div className="mt-3 text-center reset-password-success">
                  <div>
                    <i className="bx bx-mail-send" />
                  </div>

                  <p className="title">E-mail com sucesso.</p>
                  <p className="details">
                    Verifique seu email e siga as instruções para finalizar a
                    alteração da senha.
                  </p>
                  <Link className="btn button-back-to-login" to="/">
                    <i className="bx bx-chevron-left" />
                    Voltar para Login
                  </Link>
                </div>
                }                
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
          className="modal-auth-page"
          size="sm"
          isOpen={modalError}
          toggle={() => {
            toggleModalError();
          }}
          centered
        >
          <div className="modal-body">
            <DangerousOutlinedIcon />

            <p>{errorMessage}</p>

            <Button
              onClick={() => {
                toggleModalError();
                // props.router.navigate('/');
              }}
            >
              Entendi
            </Button>
          </div>
        </Modal>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
