import React from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const DistributionOfVouchers = ({ myVouchers } : any) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Distribuição dos Vales</CardTitle>
          <Row>
            <Col>
              <p className="text-muted">Este mês</p>
              <h3>{(myVouchers[0] && Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(myVouchers.reduce((acc, voucher: any) => { return acc + Number(voucher?.value || 0)}, 0))) || 0}</h3>
              <div className="d-flex">
                <p className="text-muted">
                  <span className="text-success me-2">
                    12% <i className="mdi mdi-arrow-up"></i>
                  </span>
                  Desde o ultimo período
                </p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DistributionOfVouchers;
