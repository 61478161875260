import { Container, Modal } from "reactstrap";
import moment from 'moment';

import "./receipt.styles.scss";

export default function Receipt(props?: any) {
  const { isOpen, toggle, creditData } = props;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="UserEditModal"
      tabIndex={-1}
      toggle={toggle}      
    >
      <Container className="d-flex flex-column align-items-center justify-content-center">
        <div className="receipt">
          <h3 className="text-center">
            RECIBO <span>#{creditData?.id || 0}</span>
          </h3>

          <div className="mt-5">
            <p className="receipt-company">{`${creditData?.partner?.socialName || ''} (${creditData?.partner?.fantasyName || ''})`}</p>
            {/* <p className="receipt-description">Invoice #6521</p> */}
            <p className="receipt-description">{moment(creditData?.createdAt).locale('pt-br').format('DD/MM/YYYY')}</p>

            <div className="d-flex justify-content-between">
              <p className="receipt-description">Crédito para parceiro</p>
              <p className="receipt-description">{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(creditData?.amount || '0'))}</p>
            </div>

            <div className="receipt-total">
              <p>Total</p>
              <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(creditData?.amount || '0'))}</p>
            </div>
          </div>
        </div>

        <div className="d-flex w-100 gap-4">
          {/* <button className="btn btn-receipt">
            <i className="mdi mdi-file-download-outline"></i>
            Baixar PDF
          </button> */}
          <button
            className="btn btn-receipt"
            onClick={() => toggle()}
          >
            {/* <i className="mdi mdi-share-variant-outline"></i> */}
            Fechar
          </button>
        </div>
      </Container>
    </Modal>
  );
}
