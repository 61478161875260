import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}>{new Date().getFullYear()} © S Company.</Col>
            <Col md={8}>
              <div className="d-none d-sm-flex align-items-center justify-content-end gap-2">
                <div>Termos de Uso</div>
                <div>Política de Privacidade</div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
