import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
  vouchers: [
    {
      id: null,
      socialName: '',
      fantasyName: '',
      addressStreet: '',
      addressStreetNumer: '',
      addressZipCode: '',
      addressNeighborhood: '',
      addressCity: '',
      logoUrl: '', // URL da Logo da empresa parceiro
      addressState: '',
      addressComplement: '',
      createdAt: '',
      updatedAt: '',
      deletedAt: null
    },
  ],
  vouchersMeta: {
    itemsPerPage: 0,
    totalItems: 0,
    currentPage: 1,
    totalPages: 1,
    sortBy: [
        [
            "id",
            "DESC"
        ]
    ]
  },
  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const partnerVoucherSlice = createSlice({
  name: "partnerVoucher",
  initialState,
  reducers: {
    fetchSuccess(state, action) {
      // User data
      // state.balance = action.payload.balance;
      // state.totalCounters = action.payload.totalCounters;
      // state.simpleTransactionHistory = action.payload.simpleTransactionHistory;
      // state.myVouchers = action.payload.myVouchers;
      state.vouchers = action.payload?.data;
      state.vouchersMeta = action.payload?.meta;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },    
    postSuccess(state, action) {
      // User data
      // state.vouchers = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    resetApiError(state, action) {
      state.error = '';
      state.loading = false;
      state.errorMsg = false;
    },

    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  apiError,
  isFetching,
  fetchSuccess,
  postSuccess, 
  clearState,
  resetApiError,
} = partnerVoucherSlice.actions;

export const partnerVoucherSelector = (state: RootState) => state.PartnerVoucher;

export default partnerVoucherSlice.reducer;