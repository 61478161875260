import React from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../ChartDynamicColor";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const EnergyVoucherSummary = ({ dataColors, periodData }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors);

  const options: any = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Maio",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        show: true,
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Resumo Vales Energia</CardTitle>
        <Row>
          <Col xl={3}>
            <p className="text-muted">Total Gerado</p>
            <h3>R$00,00</h3>
            {/* <div className="d-flex">
              <p className="text-muted">
                <span className="text-success me-2">
                  0% <i className="mdi mdi-arrow-up"></i>
                </span>
                From previous period
              </p>
            </div> */}
          </Col>

          <Col>
            <ReactApexChart
              options={options}
              series={[...periodData]}
              type="bar"
              height="359"
              className="apex-charts"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EnergyVoucherSummary;
