import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const initialState = {
  // User
  userId: null,
  email: "",
  user_document: "",
  name: "",
  createdAt: null,
  updatedAt: null,
  role: "",
  status: "",
  partner: {
    id: null,
    socialName: "",
    fantasyName: "",
    document: "",
    phone: "",
    logoUrl: "",
    addressStreet: "",
    addressStreetNumer: "",
    addressZipCode: "",
    addressNeighborhood: "",
    addressCity: "",
    addressState: "",
    addressComplement: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: null,
  },

  // Tokens
  refreshToken: "",
  token: "",
  tokenExpires: null,

  // layout
  emailResetSent: false,
  emailResetSucess: false,
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      // User data
      state.userId = action.payload.user.id;
      state.email = action.payload.user.email;
      state.user_document = action.payload.user.document;
      state.name = action.payload.user.name;
      state.partner = action.payload.user.partner;
      state.role = action.payload.user.role.name;
      state.status = action.payload.user.status.name;
      state.createdAt = action.payload.user.createdAt;
      state.updatedAt = action.payload.user.updatedAt;
      state.isUserLogged = true;
      // Token data
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.tokenExpires = action.payload.tokenExpires;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },

    loading(state) {
      state.loading = !state.loading;
    },

    forgotPassEmailSent(state) {
      state.emailResetSent = true;
      state.loading = false;
      state.errorMsg = false;
      state.error = "";
    },

    resetPassEmailSuccess(state) {
      state.emailResetSucess = true;
      state.loading = false;
      state.errorMsg = false;
      state.error = "";
    },

    updateUserSuccess(state, action) {
      // User data
      state.userId = action.payload.id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      // state.role = action.payload.role.name;
      state.status = action.payload.status.name;
      state.createdAt = action.payload.createdAt;
      state.updatedAt = action.payload.updatedAt;
      state.isUserLogged = true;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    resetLoginFlag(state) {
      // state.error = null;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.emailResetSent = false;
    },
    logoutUserSuccess(state, action) {
      return initialState;
    },
  },
});
export const {
  loading,
  loginSuccess,
  updateUserSuccess,
  forgotPassEmailSent,
  resetPassEmailSuccess,
  apiError,
  resetLoginFlag,
  logoutUserSuccess,
} = authSlice.actions;

export const authSelector = (state: RootState) => state.Auth;

export default authSlice.reducer;
