import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data).catch((err) => {
    let message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postFakeLogin = (data: any) =>
  api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) =>
  api.create(url.POST_EDIT_PROFILE, data);

// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data).catch((err: any) => {
    var message: any;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_PASSWORD_FORGET, data);

// postResetPwd
export const postJwtResetPwd = (data: any) => api.create(url.POST_PASSWORD_RESET, data);

// get Me Method
export const getJwtMe = (data: any) => api.get(url.GET_ME, data);

// Update User Method
export const postUpdateUser = (data: any) =>
  api.update(`${url.POST_UPDATE_USER}${data.userId}`, data);

// postSocialLogin
export const postSocialLogin = (data: any) =>
  api.create(url.SOCIAL_LOGIN, data);


// Cliente Dashbaord
export const getClientDashboard = (data: any) => api.get(url.GET_CLIENT_DASHBOARD, data);


// Partner Dashbaord
export const getParnterDashboard = (data: any) => api.get(url.GET_PARTNER_DASHBOARD, data);




// Admin Credits
export const getParntersAdmin = (data: any) => api.get(url.GET_PARTNERS_ADMIN, data);

// Admin Partners
export const postPartnerAdmin = (data: any) => api.create(url.POST_PARTNERS_ADMIN, data);

// Admin DASHBOARD
export const getAdminDashboard = (data: any) => api.get(url.GET_ADMIN_DASHBOARD, data);

// Admin Credits
export const getCreditsAdmin = (data: any) => api.get(url.GET_CREDITS_ADMIN, data);

// Admin Credits
export const postCredtisAdmin = (data: any) => api.create(url.POST_CREDITS_ADMIN, data);

// Admin Credits
// export const getVoucherPartner = (data: any) => api.get(`${url.GET_VOUCHER_PARTNER}`, data);
export const getVoucherPartner = (data: any) => api.get(`${url.GET_VOUCHER_PARTNER}?sortBy=createdAt:ASC&limit=200&filter.partner.id=${data.partnerId}`, null);

// Admin Credits
export const postVoucherPartner = (data: any) => api.create(url.POST_VOUCHER_PARTNER, data);

// Admin Backoffice
export const getPayments = (data: any) => api.get(url.GET_PAYMENT_BACKOFFICE_ADMIN, data);

// Admin Backoffice
export const getBillsAdmin = (data: any) => api.get(url.GET_BILL_ADMIN, data);

// Admin Backoffice
export const updatePayments = (data: any) => api.create(`${url.UPDATE_PAYMENT_BACKOFFICE_ADMIN}/${data.paymentId}/confirm`, data);

// Admin Backoffice
export const updateBills = (data: any) => api.create(`${url.UPDATE_BILL_BACKOFFICE_ADMIN}/${data.billId}/confirm`, data);


// Client Bill
export const getBillDataClient = (data: any) => api.get(`${url.GET_BILL_CLIENT_INFO}/${data.barcode}`, data);

// Client Bill
export const getBillData = (data: any) => api.get(`${url.GET_BILL_CLIENT}`, data);