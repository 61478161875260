import { getParnterDashboard, getParntersAdmin, postPartnerAdmin } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchSuccess,
  fetchPatnersSuccess,
} from "./reducer";

export const fetchData = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getParnterDashboard(null);

    dispatch(fetchSuccess(response));
  } catch (error) {
    console.log("erro ao fazer buscar dados", error);
    dispatch(apiError(error));
  }
};


export const fetchPartners = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getParntersAdmin(null);

    dispatch(fetchPatnersSuccess(response));
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

export const createPartner = (partner: any | null, navigate: any) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await postPartnerAdmin(partner);

    dispatch(fetchPatnersSuccess(response));

    navigate('/admin/procurar-usuario')
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

