// import { combineReducers } from "redux";

import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import storage from 'redux-persist/lib/storage'; // defaults to localStorage

import {
persistReducer,
persistStore,
FLUSH,
PERSIST,
PAUSE,
PURGE,
REGISTER,
REHYDRATE,
} from "redux-persist";

// Front
import LayoutReducer from "./layouts/reducer";
import AuthReducer from "./auth/reducer";
import ClientDashboardReducer from "./client/dashboard/reducer";
import PartnerDashboardReducer from "./partner/dashboard/reducer";
import adminDashboardReducer from "./admin/dashboard/reducer";
import adminCreditReducer from "./admin/credits/reducer";
import adminPartnerReducer from "./admin/partner/reducer";
import adminBackofficeReducer from "./admin/backoffice/reducer";
import partnerVoucherReducer from "./partner/voucher/reducer";
import clientBillReducer from "./client/bill/reducer";
import LoginReducer from "./auth/login/reducer";
import ProfileReducer from "./auth/profile/reducer";
import ForgotPasswordReducer from "./auth/forgetpwd/reducer";
import AccountReducer from "./auth/register/reducer";
import { setAuthorization } from "helpers/api_helper";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Auth: AuthReducer,
  ClientDashboard: ClientDashboardReducer,
  PartnerDashboard: PartnerDashboardReducer,
  AdminDashboard: adminDashboardReducer,
  AdminCredit: adminCreditReducer,
  AdminPartner: adminPartnerReducer,
  AdminBackoffice: adminBackofficeReducer,
  PartnerVoucher: partnerVoucherReducer,
  ClientBill: clientBillReducer,
  Login: LoginReducer,
  Profile: ProfileReducer,
  ForgetPassword: ForgotPasswordReducer,
  Account: AccountReducer
});

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, PERSIST, PURGE, REGISTER, REHYDRATE, PAUSE],
    },
  })],
});

export const persistor = persistStore(store, null, () => {
    const { Auth: auth } = store.getState()
    console.log('REHYDRATE', auth);
    if (auth.isUserLogged && auth.token) {
      setAuthorization(auth.token)
      console.log('REHYDRATE | token');
    }
});

  
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;