import React from "react";
import { Navigate } from "react-router-dom";
import { authSelector } from "slices/auth/reducer";
import { useAppSelector } from "slices";

const AuthProtected = (props) => {
  const { isUserLogged, status, role } = useAppSelector(authSelector);

  if (isUserLogged && role === 'User' && status === 'Waiting') {
    return <Navigate to={{ pathname: "/register" }} />;
  }

  if (!isUserLogged) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthProtected;
