import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
  payed: false,
  billToPay: {
    sucesso: false,
    codigoInput: "",
    mensagem: "",
    tipoCodigoInput: "",
    tipoBoleto: "",
    codigoBarras: "",
    linhaDigitavel: "",
    vencimento: null,
    amount: 0,
    discount: 0,
    company: "",
    totalToPay: 0,
    balance: 0,
    qrCode: {
      payload: "",
      base64: ""
    }
  },
  bills: [
    {
      id: 0,
      status: "",
      code: "",
      line: "",
      type: "",
      company: "",
      amount: 0,
      totalToPay: 0,
      discount: 0,
      createdAt: "",
      updatedAt: "",
      deletedAt: null,
    }
  ],
  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const clientBillSlice = createSlice({
  name: "clientBill",
  initialState,
  reducers: {
    fetchBillToPaySuccess(state, action) {
      state.billToPay = action.payload;
      state.payed = action.payload.totalToPay <= 0 ? true : false;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },    
    fetchBillsSuccess(state, action) {
      state.bills = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },    
    postSuccess(state, action) {
      // User data
      // state.vouchers = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    changePayedStatus(state, action) {
      state.payed = action.payload;
    },
    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    resetApiError(state, action) {
      state.error = '';
      state.loading = false;
      state.errorMsg = false;
    },

    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  apiError,
  isFetching,
  fetchBillsSuccess,
  fetchBillToPaySuccess,
  postSuccess, 
  changePayedStatus,
  clearState,
  resetApiError,
} = clientBillSlice.actions;

export const clientBillSelector = (state: RootState) => state.ClientBill;

export default clientBillSlice.reducer;