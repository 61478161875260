import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
    balance: {
    value: '', // saldo carteira
    },
    totalCounters: {
        totalBillsPaid: null, // Total de contas pagas
        totalBillsSaved: '', // Total em dinheiro economizado em contas
    },
    simpleTransactionHistory: [
    // lista de transações simplificado
    {
        id: null,
        description: '',
        value: '',
        expiresIn: '',
        createdAt: '',
    },
    ],
    partners: [
    {
        id: null,
        socialName: '',
        fantasyName: '',
        addressStreet: '',
        addressStreetNumer: '',
        addressZipCode: '',
        addressNeighborhood: '',
        addressCity: '',
        logoUrl: '', // URL da Logo da empresa parceiro
        addressState: '',
        addressComplement: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: null
    },
    ],

  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const adminPartnerSlice = createSlice({
  name: "adminPartner",
  initialState,
  reducers: {
    fetchSuccess(state, action) {
      // User data
      // state.balance = action.payload.balance;
      // state.totalCounters = action.payload.totalCounters;
      // state.simpleTransactionHistory = action.payload.simpleTransactionHistory;
      // state.myVouchers = action.payload.myVouchers;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    
    fetchPatnersSuccess(state, action) {
      // User data
      state.partners = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },
    
    postPatnersSuccess(state, action) {
      // User data
      // state.partners = action.payload;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },

    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    resetErrorFlag(state) {
      // state.error = null;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
    },
    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  apiError,
  isFetching,
  fetchSuccess,
  fetchPatnersSuccess,
  postPatnersSuccess,
  resetErrorFlag,
  clearState,
} = adminPartnerSlice.actions;

export const partnerPartnerSelector = (state: RootState) => state.AdminPartner;

export default adminPartnerSlice.reducer;