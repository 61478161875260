import { getBillsAdmin, getPayments, updateBills, updatePayments } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchPaymentsSuccess,
  updatePaymentStatusSuccess,
} from "./reducer";
import { fetchBillsSuccess } from "slices/admin/backoffice/reducer";

export const fetchData = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getPayments(null);

    dispatch(fetchPaymentsSuccess(response));
  } catch (error) {
    console.log("erro ao fazer buscar dados", error);
    dispatch(apiError(error));
  }
};

export const fetchBils = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getBillsAdmin(null);

    dispatch(fetchBillsSuccess(response));
  } catch (error) {
    console.log("erro ao fazer buscar dados", error);
    dispatch(apiError(error));
  }
};

export const updatePaymentStatus = (paymentId: any, updatedList: any) => async (dispatch: any) => {
  try {
    dispatch(isFetching());
    // console.log('testaaa', paymentId)
    await updatePayments({ paymentId });

    dispatch(updatePaymentStatusSuccess(updatedList));
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

export const updateBillStatus = (billId: any, updatedList: any) => async (dispatch: any) => {
  try {
    dispatch(isFetching());
    // console.log('testaaa', billId)
    await updateBills({ billId });

    dispatch(updatePaymentStatusSuccess(updatedList));
    dispatch(fetchData(null));
  } catch (error) {
    console.log("erro ao listar parceiros", error);
    dispatch(apiError(error));
  }
};

