import { Html5Qrcode } from "html5-qrcode";
import { CameraDevice } from "html5-qrcode/esm";
import { useCallback, useState } from "react";

interface IFetchCameras {
  loadingScanner: boolean;
  error?: Error;
  cameraDevices: CameraDevice[];
}
const defaultState: IFetchCameras = {
  loadingScanner: false,
  cameraDevices: [],
};

export const useFetchCameras = () => {
  const [state, setState] = useState<IFetchCameras>(defaultState);

  const fetchCameras = useCallback(async () => {
    try {
      if (!state.loadingScanner) {
        setState((prevState) => ({ ...prevState, loadingScanner: true }));
        const result = await Html5Qrcode.getCameras();
        setState({
          loadingScanner: false,
          cameraDevices: result,
        });
      }
    } catch (error) {
      setState({
        loadingScanner: false,
        error: new Error("Não permitido o uso da câmera."),
        cameraDevices: [],
      });
    }
  }, [state.loadingScanner]);

  return { state, fetchCameras };
};
