import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

import classnames from "classnames";

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Card,
  CardBody,
} from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import PersonalInformation from "Components/finalClient/perfil/personalInformation";
import Security from "Components/finalClient/perfil/security";
import CodeFA from "Components/finalClient/perfil/2FA";

import "./perfil.styles.scss";

const Perfil = () => {
  //meta title
  document.title = "Perfil | S Company";

  const [activeTab, setactiveTab] = useState<any>("1");

  const { name, email } = useAppSelector(authSelector);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Início" breadcrumbItem="Perfil" /> */}

          <div className="perfil-tabs">
            <Row className="align-items-center mb-5 info-user">
              <Col>
                <p className="message">Bem-vindo ao S Company</p>
                <h2 className="name-user">{name}</h2>
                <p className="email-user">{email}</p>
              </Col>
              <Col className="d-md-flex d-none align-items-center justify-content-end gap-5 details-user">
                {/* <div className="text-center">
                  <p className="title-detail">Telefone</p>
                  <p className="describe-detail">+55 99*****99</p>
                </div>
                <div className="text-center">
                  <p className="title-detail">CPF / CNPJ</p>
                  <p className="describe-detail">{user_document}</p>
                </div> */}
              </Col>
            </Row>

            <Row>
              <Col lg={3} sm={3}>
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1");
                      }}
                    >
                      <i className="mdi mdi-account-outline d-block check-nav-icon" />
                      <p className="fw-bold">Informações Pessoais</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2");
                      }}
                    >
                      <i className="mdi mdi-shield-check d-block check-nav-icon" />
                      <p className="fw-bold">Segurança</p>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3");
                      }}
                    >
                      <i className="mdi mdi-qrcode d-block check-nav-icon" />
                      <p className="fw-bold">2FA</p>
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
              <Col lg={9} sm={9}>
                <Card className="w-100">
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <PersonalInformation />

                      <Security />

                      <CodeFA />
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Perfil;
