import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardTitle } from "reactstrap";

import EnergyVoucherModal from "./EnergyVoucherModal";
import TableContainer from "Components/Common/TableContainer";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  BillingSocialName,
} from "./LatestTransactionCol";

import "./transactionsTable.styles.scss";

import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { partnerVoucherSelector } from "slices/partner/voucher/reducer";
import { authSelector } from "slices/auth/reducer";

import { fetchVouchers } from "slices/partner/voucher/thunk";
import Receipt from "./Receipt";


const SearchResults = () => {
  const dispatch: any = useDispatch();

  const { vouchers, loading, vouchersMeta } = useAppSelector(partnerVoucherSelector);
  const { partner } = useAppSelector(authSelector);

  const [modal1, setModal1] = useState<boolean>(false);
  const [modalDetails, setModalDetails] = useState<any>({});
  const [editDetails, setEditDetails] = useState<any>("");
  const toggleViewModal = useCallback(
    (value: any) => {
      setModal1(!modal1);
      setEditDetails(value);
    },
    [modal1]
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Filter: false,
      //   Cell: (cellProps: any) => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Nome",
        accessor: "socialName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingSocialName {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "fantasyName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Documento",
        accessor: "status",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <PaymentStatus value="Ativo" {...cellProps} />;
        },
      },
      {
        Header: "Criado Em",
        accessor: "createdAt",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Valor",
        accessor: "amount",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "Ver detalhes",
        disableFilters: true,
        accessor: "view",
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                setModalDetails(cellProps.cell.row.original)
                toggleViewModal(cellProps.cell.row.original)
              }}
            >
              {" "}
              Ver Detalhes{" "}
            </Button>
          );
        },
      },
    ],
    [toggleViewModal]
  );

  useEffect(() => {
    dispatch(fetchVouchers(partner.id));
  }, []);
  
  useEffect(() => {
    console.log('outro ', vouchers, vouchersMeta)
  }, [vouchers, vouchersMeta]);

  return (
    <React.Fragment>
      <Receipt isOpen={modal1} toggle={toggleViewModal} creditData={modalDetails} />
      <Card className="transactions-table">
        <CardBody>
          <CardTitle tag="h4" className="mb-4">
            Resultados da pesquisa
          </CardTitle>
          <TableContainer
            columns={columns}
            data={vouchers || []}
            customPageSize={10}
            isPagination={true}
            isShowingPageLength={true}
            tableClass="table table-hover dt-responsive nowrap dataTable no-footer dtr-inline"
            paginationDiv="col-sm-12 col-md-7"
            pagination="pagination justify-content-end pagination-rounded"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SearchResults;
