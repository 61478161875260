import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

import { useAppDispatch, useAppSelector } from "slices";
import { adminDashboardSelector } from "slices/admin/dashboard/reducer";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { fetchData } from "slices/admin/dashboard/thunk";

import Breadcrumb from "Components/Common/Breadcrumb";
import CardStatusAdmin from "Components/admin/dashboard/cardStatus";
import EnergyVoucherOverview from "Components/admin/dashboard/EnergyVoucherOverview";
import EnergyVoucherSummary from "Components/admin/dashboard/EnergyVoucherSummary";
import TotalUsersAndPartnersChart from "Components/admin/dashboard/TotalUsersAndPartnersChart";
import Activity from "Components/finalClient/home/activity";



import "./dashboard.styles.scss";

const yearData = [
  {
    name: "Contas Pagas",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2],
  },
  {
    name: "Vales",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
  },
];

const monthData = [
  {
    name: "Series A",
    data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
  },
  {
    name: "Series B",
    data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
  },
];

const weekData = [
  {
    name: "Series A",
    data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
  },
];

const DashboardAdmin = () => {
  document.title = "Início | S Company";
  const dispatch: any = useAppDispatch();

  const { charts, simpleHistory, totalCounters } = useAppSelector(adminDashboardSelector);

  const reports = [
    {
      title: "Total Usuários",
      iconClass: "bx-copy-alt",
      description: "114,235",
    },
    {
      title: "Total Parceiros",
      iconClass: "bx-archive-in",
      description: "25",
    },
    {
      title: "Total Contas Pagas",
      iconClass: "bx-purchase-tag-alt",
      description: "213,235,391",
    },
  ];

  const [periodData, setPeriodData] = useState<any>([]);
  const [periodType, setPeriodType] = useState<string>("yearly");

  useEffect(() => {
    if (periodType === "weekly") {
      setPeriodData(weekData);
    }
    if (periodType === "monthly") {
      setPeriodData(monthData);
    }
    if (periodType === "yearly") {
      setPeriodData(yearData);
    }
  }, [periodType]);

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
  };

  useEffect(() => {
    dispatch(fetchData(null));
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Painel" />

          <div className="d-flex gap-5 flex-column flex-lg-row">
            <div className="col col-lg-4">
              <Row>
                <Col>
                  <CardStatusAdmin data={totalCounters} />
                </Col>
              </Row>

              <Row className="d-none d-lg-flex mt-5">
                <Col>
                  <Activity data={simpleHistory} />
                </Col>
              </Row>
            </div>

            <Col>
              <Row>
                {/* Reports Render */}
                  <Col xl={4}>
                    <Card className="mini-stats-wid card-dashboard">
                      <CardBody className="d-flex">
                        <div className="flex-grow-1">
                          <p className="title-card">Total Usuários</p>
                          <h4 className="card-description">
                            {/* <span>R$</span> */}
                            {totalCounters.users}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx bx-copy-alt font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={4}>
                    <Card className="mini-stats-wid card-dashboard">
                      <CardBody className="d-flex">
                        <div className="flex-grow-1">
                          <p className="title-card">Total Parceiros</p>
                          <h4 className="card-description">
                            {/* <span>R$</span> */}
                            {totalCounters.users}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx bx-archive-in font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={4}>
                    <Card className="mini-stats-wid card-dashboard">
                      <CardBody className="d-flex">
                        <div className="flex-grow-1">
                          <p className="title-card">Total Contas Pagas</p>
                          <h4 className="card-description">
                            {/* <span>R$</span> */}
                            {totalCounters.totalBillsPaid}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx bx-purchase-tag-alt font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
              </Row>
              <Row>
                <Col>
                  <EnergyVoucherSummary
                    periodData={periodData}
                    dataColors='["#495057", "#DEE1F3"]'
                  />
                </Col>
              </Row>

              <Row>
                {/* <Col xl={6}>
                  <Card>
                    <CardBody>
                      <TotalUsersAndPartnersChart dataColors='["#495057", "#DEE1F3"]' />
                    </CardBody>
                  </Card>
                </Col> */}

                {/* <Col xl={6}>
                  <Card>
                    <CardBody>
                      <CardTitle tag="h4" className="mb-4">
                        Visão geral Vale Energia
                      </CardTitle>
                      <EnergyVoucherOverview dataColors='["#DEE1F3", "#BFC2D2", "#495057"]' />
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </Col>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardAdmin;
