import moment from "moment";
import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

const EcommerceOrdersModal = (props?: any) => {
  const { isOpen, toggle, editDetails, creditData } = props;

  console.log('creditData', creditData);
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex={-1}
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Comprovante</ModalHeader>
        <ModalBody>
          <h1 className="text-left" style={{ fontSize: '18px' }}>#{creditData?.id}</h1>
          <p className="mb-1">
            Tipo:{" "}
            <span className="" style={{ color: '#87B30A !important' }}>Conta de Energia</span>
          </p>
          <p className="mb-1">
            Empresa Energia:{" "}
            <span className="" style={{ color: '#87B30A !important' }}>{creditData?.company || ''}</span>
          </p>
          <p className="mb-4">
            Data solicitação pagamento:{" "}
            <span className="" style={{ color: '#87B30A !important' }}>{moment(creditData?.createdAt).utc('America/Sao_Paulo').format('DD/MM/YYYY') || ''}</span>
          </p>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              {/* <thead>
                <tr>
                  <th scope="col">Logo</th>
                  <th scope="col">Parceiro</th>
                  <th scope="col">Vale</th>
                </tr>
              </thead> */}
              <tbody>
                {/* <tr>
                  <th scope="row">
                    <div>
                      <img src="https://i.ibb.co/vcXG9GW/Grupo-Arnone-Logomarca-01.png" alt="" className="avatar-sm" />
                    </div>
                  </th>
                  <td>
                      <h5 className="text-truncate font-size-14">
                        Grupo Arnone
                      </h5>
                  </td>
                  <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(parseFloat('50' || '0'))}</td>
                </tr> */}
                <tr>
                  <td colSpan={2}>
                    <h6 className="m-0 text-right">Valor da Conta:</h6>
                  </td>
                  <td style={{ textAlign: 'right' }}>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(parseFloat(creditData?.amount || '0'))}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h6 className="m-0 text-right">Complemento PIX:</h6>
                  </td>
                  <td style={{ textAlign: 'right' }}>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(parseFloat(creditData?.totalToPay || '0'))}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h6 className="m-0 text-right">Vale Energia:</h6>
                  </td>
                  <td style={{ textAlign: 'right' }}>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(parseFloat(creditData?.discount || '0'))}</td>
                </tr>
                {/* <tr>
                  <td colSpan={2}>
                    <h6 className="m-0 text-right">Valor Pago (PIX):</h6>
                  </td>
                  <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(parseFloat('300' || '0'))}</td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
            <div className="d-flex w-100 gap-4">
              <button className="btn btn-receipt">
                <i className="mdi mdi-file-download-outline"></i>
                Baixar PDF
              </button>
              <button
                className="btn btn-receipt"
                onClick={() => toggle()}
              >
                <i className="mdi mdi-window-close"></i>
                Fechar
              </button>
            </div>
          {/* <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button> */}
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default EcommerceOrdersModal;
